import {Component, isDevMode, OnDestroy} from '@angular/core';
import {AuthService} from './core/services/auth.service';
import {LanguageService} from './core/services/language.service';
import {ConfigService} from './core/services/config.service';
import {InboxService} from './shared/services/inbox.service';
import {User} from './core/models/user';
import {ActivatedRoute, Router } from '@angular/router';
import {WebsocketsService} from './core/services/websockets.service';
import {AngularFaviconService} from 'angular-favicon';
import {KioskModeService} from './core/services/kiosk-mode.service';
import {ViewSizeService, ViewWide} from './shared/services/view-size.service';
import {VersionCheckerService} from './shared/services/version-checker.service';
import {LoggingService} from './core/logging/logging.service';
import {LoggingSource} from './core/logging/loggingSource';
import {RolesService} from './core/services/roles.service';
import {EnterpriseRoleProviderService} from './core/services/enterprise-role-provider.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {QueryParamsService} from './core/services/query-params.service';
import {UserStatisticsProviderService} from './core/services/user-statistics-provider.service';

declare let $: any;

@Component({
  selector: 'op-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy{
  private onComponentDestroyed: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router,
              private authService: AuthService,
              private languageService: LanguageService,
              private configService: ConfigService,
              private inboxService: InboxService,
              private route: ActivatedRoute,
              private websocketService: WebsocketsService,
              private ngxFavicon: AngularFaviconService,
              public  kioskModeService: KioskModeService,
              private viewSizeService: ViewSizeService,
              private versionCheckerService: VersionCheckerService,
              private roles: RolesService,
              private userStatisticsProvider: UserStatisticsProviderService) {

    this.languageService.initLanguage();
    this.ngxFavicon.setFavicon('assets/img/logo_black.png', 'assets/img/logo_white.png');
    this.kioskModeService.kioskMode$.subscribe(mode => this.kioskMode = mode);

    this.viewSizeService.viewWide$.subscribe(size => {
      this.viewWide = size;

      if (isDevMode()) {
        console.log(new Date().toLocaleString() + ': Current viewSize [' + this.viewWide + ']');
      }
    });

    this.authService.configureAuth().then(() => {
      this.portalVersion = this.configService.getVersion();
      LoggingService.appVersion = this.portalVersion;
    });

    authService.loggedIn.subscribe(loggedIn => {
      this.loggedIn = loggedIn;

      if (!this.loggedIn) {
        this.currentUser = undefined;
        this.websocketService.close();
      } else {

        this.roles.initPermissions();
        this.currentUser = this.authService.currentUser;



        LoggingService.clientId = this.authService.currentUser.username;
        LoggingService.logInfo(LoggingSource.CORE, `Login of ${LoggingService.clientId} successful. LoginType: ${(this.authService.isApplicationUser ? 'Application' : 'User')}.`);

        this.versionCheckerService.init();
        this.versionCheckerService.newVersionAvailableEvent.subscribe(event => {
          this.newPortalVersion = event.deployedVersion.version;
        });

      }
    });

    EnterpriseRoleProviderService.onPermissionsLoaded.subscribe(() => {
      if (EnterpriseRoleProviderService.hasEffectiveRoles) {
        this.inboxService.init();
      }
    });


    this.router.events.subscribe(event => {
      const navigationInit: number = event[`type`];

      if (navigationInit === 0) {
        EnterpriseRoleProviderService.claimMissing = false;
        EnterpriseRoleProviderService.lastClaimNameRequested = undefined;
        EnterpriseRoleProviderService.lastClaimChecked = undefined;
      }

      const uri: string = event[`url`];
      if (uri) {
        if (uri.startsWith('/tools/welcome-screen')) {
          this.pageMode = PageMode.WELCOME_SCREEN;
        } else if (uri.startsWith('/home/landing-zone')) {
          this.pageMode = PageMode.LANDING_ZONE;
        } else {
          this.pageMode = PageMode.DEFAULT;
        }
      }
    });

    this.route.queryParamMap
      .pipe(takeUntil(this.onComponentDestroyed))
      .subscribe(queryP => {
      this.logMode = queryP.get('logMode') === 'true';
    });

    if (!QueryParamsService.isStarted) {
      QueryParamsService.start(this.route);
    }


  }
  public currentUser: User;
  public loggedIn = false;
  public portalVersion: string;
  public newPortalVersion: string;
  public kioskMode = false;
  public viewWide: ViewWide;
  public pageMode: PageMode = PageMode.DEFAULT;
  public logMode: boolean;
  public sidebarCollapsed= false;

  protected readonly PageMode = PageMode;
  protected readonly LoggingService = LoggingService;
  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;


  onLogout() {
    this.authService.logout();
  }

  toggleKioskMode() {
    this.kioskModeService.toggleKioskMode();
  }

  toggleLogMode() {
    this.logMode = !this.logMode;
    if (this.logMode) {
      LoggingService.clearMessageIndication();
    }
  }

  public ngOnDestroy(): void {
    this.onComponentDestroyed.next(true)
    this.onComponentDestroyed.complete();
  }
}

export enum PageMode {
  DEFAULT= 'DEFAULT',
  LANDING_ZONE = 'LANDING_ZONE',
  WELCOME_SCREEN = 'WELCOME_SCREEN'
}

