import {EventEmitter, Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {AuthService} from './auth.service';
import {ConfigService} from './config.service';
import {ApplicationControllerService} from '@cstx/volkswagen-mqs-application-management-service-client';

@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {
  private socket: WebSocket;
  private listener: EventEmitter<any> = new EventEmitter();

  public constructor(private authService: AuthService,
                     private configService: ConfigService,
                     private applicationControllerService: ApplicationControllerService) {}

  public send(data: string) {
    this.socket.send(data);
  }

  public close() {
    if (this.socket) {
      this.socket.close();
    }
  }

  public getEventListener() {
    return this.listener;
  }

  public initWebSocketConnection() {
    if (this.authService.isApplicationUser) {
      firstValueFrom(this.applicationControllerService.getSelf({})).then(res => {
        if ( res.scopes.findIndex(scope => scope.service === 'NOTIFICATION_SERVICE' && scope.canRead ) > -1 ) {
          this.initSocket();
        }
      });
    } else {
      this.initSocket();
    }
  }

  private initSocket() {
    const nnsUrl = this.configService.getNnsApiUrl().replace('http', 'ws') + '/ws';
    this.socket = new WebSocket(nnsUrl);
    this.socket.onopen = event => {
      this.send(this.authService.currentUser?.email);
      this.listener.emit({type: 'open', data: event});
    };
    this.socket.onclose = event => {
      this.listener.emit({type: 'close', data: event});
    };
    this.socket.onmessage = event => {
      this.listener.emit({type: 'message', data: JSON.parse(event.data)});
    };
  }
}
