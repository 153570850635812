// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  showSwaggerLinks: true,
  envConfigUrl: '/config.json',
  authUrl: 'https://idp.mqs-sz.cloud/auth/realms/cpp',
  mdsApiUrl: 'https://mds.dev.volkswagen-mqs.de',
  // mdsApiUrl: 'http://localhost:8080',
  mmsApiUrl: 'https://mms.dev.volkswagen-mqs.de',
  eesApiUrl: 'https://ees.dev.volkswagen-mqs.de',
  // eesApiUrl: 'http://localhost:8081',
  // wssApiUrl: 'http://localhost:8080',
  wssApiUrl: 'https://wss.dev.volkswagen-mqs.de',
  amsApiUrl: 'https://ams.dev.volkswagen-mqs.de',
  fhsApiUrl: 'https://fhs.dev.volkswagen-mqs.de',
  // fhsApiUrl: "http://localhost:8080",
  pssApiUrl: 'https://pss.dev.volkswagen-mqs.de',
  // pssApiUrl: 'http://localhost:6001',
  ptsApiUrl: 'https://pts.dev.volkswagen-mqs.de',
  // ptsApiUrl: 'http://localhost:8080',
  trsApiUrl: 'https://trs.dev.volkswagen-mqs.de',
  // trsApiUrl: 'https://localhost:5001',
  // vwsApiUrl: "https://vws.dev.volkswagen-mqs.de",
  vwsApiUrl: 'https://vws.dev.volkswagen-mqs.de',
  nnsApiUrl: 'https://nns.dev.volkswagen-mqs.de',
  qmsApiUrl: 'https://qms.dev.volkswagen-mqs.de',
  // qmsApiUrl: 'https://localhost:5001',
  mpsApiUrl: 'https://mps.dev.volkswagen-mqs.de',
  opsApiUrl: 'https://ops.dev.volkswagen-mqs.de',
  ucsApiUrl: 'https://ucs.dev.volkswagen-mqs.de',
  // ucsApiUrl: 'https://localhost:5001',
  tgsApiUrl: 'https://tgs.dev.volkswagen-mqs.de',
  // tgsApiUrl: "http://localhost:8080",
  dlqApiUrl: 'https://dlq.dev.volkswagen-mqs.de',
  rgsApiUrl: 'https://rgs.dev.volkswagen-mqs.de',
  // rgsApiUrl: "https://localhost:8001" ,
  fctApiUrl: 'https://fct.dev.volkswagen-mqs.de',
  sasApiUrl: 'https://sas.dev.volkswagen-mqs.de',
  ldsApiUrl: 'https://lds.dev.volkswagen-mqs.de',
  clientId: 'dev.volkswagen-mqs.de',
  version: '0.0',
  stage: 'localhost',
  userRole: 'VWAG_SZONLINE_USR',
  qaUserRole: 'VWAG_SZONLINE_QS',
  adminUserRole: 'VWAG_SZONLINE_ADMIN',
  betaUserRole: 'VWAG_SZONLINE_BETA',
  tracerBaseUrl: 'https://grafana.mqs-sz.cloud',
  portalMode: 'cpo'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
