import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {
  public static isStarted = false;

  public static traceLogEnabled = false;
  public static consoleLogOnly = false;

  private static unsubscribe
    = new Subject<boolean>();


  public static start(route: ActivatedRoute) {
    route.queryParamMap
      .pipe(takeUntil(QueryParamsService.unsubscribe))
      .subscribe(queryParams => {

        if (isDevMode()) {
          QueryParamsService.traceLogEnabled = true;
          QueryParamsService.consoleLogOnly = true;
        } else {
          QueryParamsService.traceLogEnabled = queryParams.get('traceLogEnabled') === 'true';
          QueryParamsService.consoleLogOnly = queryParams.get('consoleLogOnly') === 'true';
        }


        QueryParamsService.isStarted = true;
      });
  }

  public static stop() {
    QueryParamsService.unsubscribe.next(true);
    QueryParamsService.unsubscribe.complete();

    QueryParamsService.isStarted = false;
  }
}
