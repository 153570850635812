export enum LoggingSource {
  CORE = 'CORE',
  FEAT = 'FEAT',
  STACKING_SCANNER = 'STACKING_SCANNER',
  STACKING_QUEUE = 'STACKING_QUEUE',
  STACK = 'STACK',
  STACK_SERVICE = 'STACK_SERVICE',
  SCAN_COMPONENT = 'SCAN_COMPONENT',
  SCANNER_COMPONENT = 'SCANNER_COMPONENT',
  BIG_SCREEN = 'BIG_SCREEN',
  VERSION_CHECKER = 'VERSION_CHECKER',
  TOOL_CORRECTION = 'TOOL_CORRECTION',
  APPLICATION_MANAGEMENT_SERVICE = 'APPLICATION_MANAGEMENT_SERVICE',
  COSTCENTER_PROFILE_SERVICE = 'COSTCENTER_PROFILE_SERVICE',
  COMPONENT_PROFILE_ACTION_REWORK = 'COMPONENT_PROFILE_ACTION_REWORK',
  STACKING_APP_CONFIGURATION = 'STACKING_APP_CONFIGURATION',
  TRACKING_SERVICE = 'TRACKING_SERVICE',
  ENGINE_SERVICE = 'ENGINE_SERVICE',
  PART_SERVICE = 'PART_SERVICE',
  LOADINGS_COMPONENT = 'LOADINGS_COMPONENT',
  TELEGRAM_RECEIVER_SERVICE = 'TELEGRAM_RECEIVER_SERVICE',
  LOCAL_STORAGE_MANAGER = 'LOCAL_STORAGE_MANAGER',
  MANUFACTURING_MONITOR_SERVICE = 'MANUFACTURING_MONITOR_SERVICE ',
  QUALITY_MANAGEMENT_SERVICE ='QUALITY_MANAGEMENT_SERVICE',
  BLOCKING_ACTION_FORM ='BLOCKING_ACTION_FORM',
  ROLE_MANAGEMENT = 'ROLE_MANAGEMENT',
  UPLOAD_V2_SERVICE = 'UPLOAD_V2_SERVICE',
  OTHER = 'OTHER',
  FILE_HANDLING_SERVICE = 'FILE_HANDLING_SERVICE',
  EMPLOYEE_INFO_COMPONENT = 'EMPLOYEE_INFO_COMPONENT',
  APPLICATION_EDIT = 'APPLICATION_EDIT',
  FILE_PREVIEW = 'FILE_PREVIEW',
  INFO_SERVICE = 'INFO_SERVICE',
  COMPONENT_CACHE = 'COMPONENT_CACHE',
  DASHBOARD = 'DASHBOARD',
  SERVICE_STATUS = 'SERVICE_STATUS',
  PART_LIST = 'PART_LIST',
  PART_NUMBER_DEFINITION_SERVICE = 'PART_NUMBER_DEFINITION_SERVICE',
  REPORT_SERVICE = 'REPORT_SERVICE',
  FILE_PREVIEW_USECASE = 'FILE_PREVIEW_USECASE',
  ENGINE_DETAIL = 'ENGINE_DETAIL',
  ENGINE_LIST = 'ENGINE_LIST',
  EVENT_LIST = 'EVENT_LIST',
  EVENT_SERVICE = 'EVENT_SERVICE',
  PREVIEW = 'PREVIEW',
  FILE_EXPLORER_SERVICE = 'FILE_EXPLORER_SERVICE',
  PART_NUMBER_DEFINITIONS = 'PART_NUMBER_DEFINITIONS',
  REFRESH_COMPONENT = 'REFRESH_COMPONENT',
  PARSING_SERVICE = 'PARSING_SERVICE',
  USER_CONFIGURATION_SERVICE = 'USER_CONFIGURATION_SERVICE',
  FILTER = 'FILTER',
  COMPONENT_PROFILE_SERVICE =  'COMPONENT_PROFILE_SERVICE',
  ENGINE = 'ENGINE',
  COMPONENT_BLOCK_WIZARD_SERVICE = 'COMPONENT_BLOCK_WIZARD_SERVICE',
  COMPONENT_RELEASE_WIZARD_SERVICE = 'COMPONENT_RELEASE_WIZARD_SERVICE',
  COMPONENT_BLOCK_WIZARD_COMPONENT = 'COMPONENT_BLOCK_WIZARD_COMPONENT',
  COMPONENT_PROFILE_COMPONENT = 'COMPONENT_PROFILE_COMPONENT',
  METADATA_SERVICE = 'METADATA_SERVICE',
  INBOX_COMPONENT = 'INBOX_COMPONENT',
  LOGISTICS_DATA_SERVICE = 'LOGISTICS_DATA_SERVICE',
  BUILD_PROGRAM_PLANNING = 'BUILD_PROGRAM_PLANNING',
  TRANSLATION_LOADER = 'TRANSLATION_LOADER',
  BILL_OF_MATERIALS = 'BILL_OF_MATERIALS'
}
