import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'op-component-state-badge',
  templateUrl: './component-state-badge.component.html',
  styleUrls: ['./component-state-badge.component.scss']
})

export class ComponentStateBadgeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() name = '';
  @Input() useIcons = false;
  @Input() useIconsAndText = false;
  @Input() prependIcon = false;
  @Input() customBadgeClass: string = undefined;
  @Input() small: boolean;

  i18n: string;
  badgeClass: string;
  badgeLabel: string;
  badgeIconClass: string;
  labelText: string;

  private badgeClassMapping = [
    {label: 'COMPLETE', value: 'badge-success' },
    {label: 'COMPLETE_BLOCKED', value: 'cpp-badge-blocked' },
    {label: 'READY_BLOCKED', value: 'cpp-badge-blocked' },
    {label: 'MBK', value: 'cpp-badge-mbk' },
    {label: 'GTL', value: 'cpp-badge-gtl' },
    {label: 'ENGINE', value: 'cpp-badge-enginelabel' },
    {label: 'ENGINE_NAME', value: 'cpp-badge-enginelabel' },
    {label: 'VW01064', value: 'cpp-badge-vw01064' },
    {label: 'WSK', value: 'cpp-badge-wsk' },
    {label: 'PRODUCTION_FINISHED', value: 'badge-success'},
    {label: 'IN_PRODUCTION', value: 'cpp-badge-in-production'},
    {label: 'DONE', value: 'badge-success'},
    {label: 'TRUE', value: 'badge-success', iconClass: 'fa-check'},
    {label: 'FALSE', value: 'badge-danger', iconClass: 'fa-times'},
    {label: 'SHIPPED',  value: 'cpp-badge-shipped'},
    {label: 'STACKED',  value: 'cpp-badge-stacked'},
    {label: 'READY_TO_SHIP', value: 'cpp-badge-ready-to-ship'},
    {label: 'RETURNED', value: 'cpp-badge-returned'},
    {label: 'MANUFACTURED',  value: 'cpp-badge-manufactured'},
    {label: 'DISASSEMBLED',  value: 'badge-danger'},
    {label: 'FORCED_READY_TO_SHIP', value: 'cpp-badge-force-ready-to-ship'},
    {label: 'PARTIAL', value: 'cpp-badge-partial'},
    {label: 'INCOMPLETE', value: 'cpp-badge-incomplete'},
    {label: 'UNKNOWN', value: 'cpp-badge-unknown'},
    {label: 'INLINE', value: 'badge-primary' },
    {label: 'PART_INSTALLED', value: 'badge-primary' },
    {label: 'PART_REMOVED', value: 'badge-primary' },
    {label: 'STACKING', value: 'badge-primary' },
    {label: 'UNSTACKING', value: 'badge-primary' },
    {label: 'DISPATCH', value: 'badge-primary' },
    {label: 'IMPORTED', value: 'badge-primary' },
    {label: 'INVENTORIED', value: 'badge-primary' },
    {label: 'COLD_TEST_FAILED', value: 'badge-danger' },
    {label: 'HOT_TEST_FAILED', value: 'badge-danger' },
    {label: 'BLOCKED', value: 'cpp-badge-blocked' },
    {label: 'ERROR', value: 'badge-danger' },
    {label: 'RETURN', value: 'badge-warning' },
    {label: 'IN_REVIEW', value: 'badge-warning' },
    {label: 'REWORK_OPEN', value: 'badge-warning' },
    {label: 'REWORK_CLOSED', value: 'badge-warning' },
    {label: 'COLD_TEST_PASSED', value: 'badge-success' },
    {label: 'HOT_TEST_PASSED', value: 'badge-success' },
    {label: 'RELEASED', value: 'badge-success' },
    {label: 'REWORK_APPROVED', value: 'badge-success' },
    {label: 'UNKNOWN', value: 'badge-secondary' },
    {label: 'WORKLOAD', value: 'badge-success'},
    {label: 'EMERGENCY', value: 'badge-danger' },
    {label: 'QGATE', value: 'badge-secondary' },
    {label: 'BRAUNSCHWEIG', value: 'cpp-badge-braunschweig' },
    {label: 'SALZGITTER', value: 'cpp-badge-salzgitter' },
    {label: 'CHEMNITZ', value: 'cpp-badge-chemnitz' },
    {label: 'ZWICKAU', value: 'cpp-badge-zwickau' },
    {label: 'POLKOWICE', value: 'cpp-badge-polkowice' },
    {label: 'DRESDEN', value: 'cpp-badge-dresden' },
    {label: 'PRIMARY_PART', value: 'cpp-badge-primary-part' },
    {label: 'ALTERNATIVE_PART', value: 'cpp-badge-alternative-part' },
    {label: 'UNKNOWN_PART', value: 'cpp-badge-unknown-part' },
    {label: 'COLD_TEST', value: 'cpp-badge-cold-test', iconClass: 'fa-snowflake' },
    {label: 'HOT_TEST', value: 'cpp-badge-hot-test', iconClass: 'fa-fire' },
    {label: 'LEAK_TEST', value: 'cpp-badge-leak-test', iconClass: 'fa-fill-drip'},
    {label: 'MECHANICAL_TEST', value: 'cpp-badge-mechanical-test', iconClass: 'fa-wrench'},
    {label: 'ELECTRONIC_CHECK', value: 'cpp-badge-electrical-check', iconClass: 'fa-bolt'},
    {label: 'INLINE_REWORK', value: 'cpp-badge-cold-test', iconClass: 'fa-link'},
    {label: 'OFFLINE_REWORK', value: 'cpp-badge-leak-test', iconClass: 'fa-external-link-alt'},
    {label: 'SZONLINEV1', value: 'badge-secondary', iconClass: 'fa-database' },
    {label: 'OFFLINE', value: 'cpp-badge-darkred', iconClass: 'fa-unlink'},
    {label: 'ONLINE', value: 'cpp-badge-darkgreen', iconClass: 'fa-link'},
    {label: 'READY', value: 'cpp-badge-darkgreen', iconClass: 'fa-link'},
    {label: 'NOTREADY', value: 'cpp-badge-darkred', iconClass: 'fa-external-link-alt'},
    {label: 'STACKCARRIERMISSING', value: 'cpp-badge-darkgrey', iconClass: 'fa-database' },
    {label: 'COMPONENTTYPEMISMATCH', value: 'cpp-badge-darkgrey', iconClass: 'fa-database' },
    {label: 'REFRESHING', value: 'cpp-badge-darkgoldenrod', iconClass: 'fa-link'},
    {label: 'MACHINE_ERROR', value: 'badge-danger' },
    {label: 'MATERIAL_ERROR', value: 'badge-danger'},
    {label: 'CHECKED', value: 'badge-success'},
    {label: 'REWORKING', value: 'badge-warning'},
    {label: 'LABEL_PRINTED', value: 'cpp-badge-darkgoldenrod'},
    {label: 'LABEL_VALIDATED', value: 'badge-success'},
    {label: 'LABEL_VALIDATION_SKIPPED', value: 'cpp-badge-darkgrey'},
    {label: 'LABEL_ENGINE_VALIDATE_SKIP', value: 'cpp-badge-darkgrey'},
    {label: 'KEYCODE_CHANGED', value: 'badge-warning'},
    {label: 'ACTIVE', value: 'cpp-badge-darkgreen'},
    {label: 'INACTIVE', value: 'cpp-badge-darkred'},
    {label: 'LEFT', value: 'cpp-badge-bigscreen-sides'},
    {label: 'RIGHT', value: 'cpp-badge-bigscreen-sides'},
    {label: 'MARQUEE', value: 'cpp-badge-bigscreen-marquee'},
    {label: 'IDLE', value: 'badge-secondary'},
    {label: 'IN_PROGRESS', value: 'badge-warning'},
    {label: 'FAILED', value: 'badge-error'},
    {label: 'PENDING', value: 'cpp-badge-wsk'},
    {label: 'DELETED', value: 'badge-secondary'},
    {label: 'PENDINGBLOCKING', value: 'badge-warning'},
    {label: 'PENDINGRELEASE', value: 'badge-secondary'},
    {label: 'NOTFOUND', value: 'badge-danger'},
    {label: 'PENDINGRELEASE', value: 'badge-secondary'},
    {label: 'AwaitingTranslationOfComponentNames', value: 'cpp-badge-unknown'},
    {label: 'OFFSET_ADDED', value: 'badge-secondary'},
    {label: 'OFFSET_DELETED', value: 'badge-danger'},
    {label: 'SZOnline 1.0', value: 'badge-danger'},
    {label: 'Dynamic', value: 'badge-warning'},
    {label: 'PAUSED', value: 'badge-secondary'},
    {label: 'CANCELATIONREQUESTED', value: 'cpp-badge-mbk'},
    {label: '', value: 'cpp-badge-unknown'}
  ];
  private unsubSub: Subject<void> = new Subject<void>();




  constructor(private translate: TranslateService) {
    this.translate.onLangChange.pipe(takeUntil(this.unsubSub.asObservable())).subscribe(() => {
      this.init();
    });
  }

  ngOnDestroy(): void {
    this.unsubSub.next();
  }

  ngOnInit(): void {
    this.init()
  }

  ngOnChanges(): void {
    this.init();
  }

  private init() {
    this.i18n       = 'componentStateBadge.name.' + this.name?.toLocaleLowerCase();
    this.badgeLabel = this.getLabel();
    this.badgeClass = this.getBadgeClass();
    this.badgeIconClass = this.getIconClass();
  }

  getBadgeClass() {
    if (this.customBadgeClass !== undefined) {
      return this.customBadgeClass;
    }

    const badgeClassMap = this.badgeClassMapping.find(s => s.label.toLocaleLowerCase() === this.name?.toLocaleLowerCase());
    if (badgeClassMap === undefined) {
      return 'badge-dark';
    }

    return badgeClassMap.value;
  }

  getLabel() {
    if (this.name === '') {
      return 'Unknown';
    }

    const translated = this.translate.instant(this.i18n);

    if (translated === this.i18n) {
      return this.name;
    }

    return translated;
  }

  getIconClass(): string {
    const iconClassMap = this.badgeClassMapping.find(s => s.label.toLocaleLowerCase() === this.name?.toLocaleLowerCase());

    if (iconClassMap === undefined) {
      return '';
    }

    return iconClassMap.iconClass ? iconClassMap.iconClass : '';
  }
}
