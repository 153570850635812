import {Injectable, isDevMode} from '@angular/core';
import {Configuration} from '../../config/configuration';
import {map, publishLast, refCount} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';

import {LocalStorageManagerService} from '../../shared/services/local-storage-manager.service';
import {STAGE} from '../models/stage';
import {ViewSizeService, ViewWide} from '../../shared/services/view-size.service';
import {LoggingService} from '../logging/logging.service';
import {LoggingSource} from '../logging/loggingSource';
import {KioskModeService} from './kiosk-mode.service';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {


  constructor(private http: HttpClient,
              private kioskModeService: KioskModeService,
              private viewSizeService: ViewSizeService,) {

    ConfigService.updateTimeAndDate();

    this.initViewWideMonitoring();
    this.initKioskModeMonitoring();

    const urlSearchParams = new URLSearchParams(window.location.search);
    this.setApplicationCredentials(urlSearchParams.get('appName'), urlSearchParams.get('appToken'));

    setInterval(() => {
      ConfigService.updateTimeAndDate();
    }, 1000);
  }

  public static currentStage: STAGE = STAGE.LOCALHOST;
  public static currentVersion: string = '0.0';
  public static configuration: Configuration;
  public static headerTitle: string;

  public static calendarWeek: number;
  public static year: number;
  public static month: number;
  public static day: number;
  public static time: string;

  private static bigMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static bigMode$: Observable<boolean> = ConfigService.bigMode.asObservable();

  private static kioskMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static kioskMode$: Observable<boolean> = ConfigService.kioskMode.asObservable();

  private static viewWide: BehaviorSubject<ViewWide> = new BehaviorSubject<ViewWide>(undefined);
  public static viewWide$: Observable<ViewWide> = ConfigService.viewWide.asObservable();

  private currentViewWide: ViewWide;
  private currentKioskMode: boolean;


  public configuration: Configuration;


  private fallbackConfig = {
    authUrl: environment.authUrl,
    showSwaggerLinks: environment.showSwaggerLinks,
    clientId: environment.clientId,
    mdsApiUrl: environment.mdsApiUrl,
    mmsApiUrl: environment.mmsApiUrl,
    eesApiUrl: environment.eesApiUrl,
    wssApiUrl: environment.wssApiUrl,
    fhsApiUrl: environment.fhsApiUrl,
    amsApiUrl: environment.amsApiUrl,
    ptsApiUrl: environment.ptsApiUrl,
    pssApiUrl: environment.pssApiUrl,
    opsApiUrl: environment.opsApiUrl,
    nnsApiUrl: environment.nnsApiUrl,
    qmsApiUrl: environment.qmsApiUrl,
    mpsApiUrl: environment.mpsApiUrl,
    tgsApiUrl: environment.tgsApiUrl,
    rgsApiUrl: environment.rgsApiUrl,
    dlqApiUrl: environment.dlqApiUrl,
    ucsApiUrl: environment.ucsApiUrl,
    trsApiUrl: environment.trsApiUrl,
    vwsApiUrl: environment.vwsApiUrl,
    fctApiUrl: environment.fctApiUrl,
    sasApiUrl: environment.sasApiUrl,
    ldsApiUrl: environment.ldsApiUrl,
    stage: environment.stage,
    version: environment.version,
    traceUrl: undefined,
    userRole: environment.userRole,
    qaUserRole: environment.qaUserRole,
    adminUserRole: environment.adminUserRole,
    betaUserRole: environment.betaUserRole,
    tracerBaseUrl: environment.tracerBaseUrl,
    portalMode: environment.portalMode,
  };


  public static getSenderEmail(): string {
    if (ConfigService.currentStage === STAGE.PRODUCTION) {
      return 'no-reply@volkswagen-mqs.de';
    }

    if (ConfigService.currentStage === STAGE.INTEGRATION) {
      return 'no-reply@int.volkswagen-mqs.de';
    }

    return 'no-reply@dev.volkswagen-mqs.de';
  }

  public static updateTimeAndDate() {
    const currentDate = new Date();

    ConfigService.calendarWeek = moment(currentDate).week();
    ConfigService.year = moment(currentDate).year();
    ConfigService.month = moment(currentDate).month();
    ConfigService.day = moment(currentDate).day();
    ConfigService.time = moment(currentDate).hour().toString().padStart(2, '0') + ':' +  moment(currentDate).minute().toString().padStart(2, '0')
  }

  public static getSupportEmail(): string[] {
    if (ConfigService.currentStage === STAGE.PRODUCTION) {
      return [ 'probleme@volkswagen-mqs.de' ];
    }

    if (ConfigService.currentStage === STAGE.INTEGRATION) {
      return [ 'probleme@volkswagen-mqs.de' ];
    }

    if (ConfigService.currentStage === STAGE.DEVELOP) {
      return [
        'bastian.klein@cstx.de',
        'robert.wildangier@cstx.de',
        'bastian.dettmann@volkswagen.de',
        'christoph.darms@cstx.de'
      ];
    }

    if (isDevMode()) {
      return [ 'bastian.klein@cstx.de' ];
    }

    return [ 'probleme@volkswagen-mqs.de' ];
  }

  private toggleBigMode() {
    const bigMode = (this.currentKioskMode) && (this.currentViewWide === ViewWide.XL || this.currentViewWide === ViewWide.XXL);
    ConfigService.bigMode.next(bigMode);

    LoggingService.logInfo(LoggingSource.CORE,
      `Currently bigMode is set to: ${bigMode}.`);
  }

  initializeApp(): Promise<any> {

    return new Promise((resolve) => {
          this.http.get(environment.envConfigUrl, {
            responseType: 'json'
          })
          .pipe(
              publishLast(),
              refCount(),
              map(value => value as Configuration))
          .toPromise()
          .then(response => {
                this.configuration = response;

                ConfigService.configuration = response;
                ConfigService.currentStage = this.getStageEnum();
                ConfigService.currentVersion = this.getVersion();

                resolve(null);
              }
          )
          .catch(reason => {
            this.configuration = this.fallbackConfig;
            ConfigService.configuration = this.fallbackConfig;
            ConfigService.currentStage = this.getStageEnum();
            ConfigService.currentVersion = this.getVersion();
            resolve(null);
          });
        }
    );
  }

  getStageEnum(): STAGE {
    if (!this.configuration.stage || this.configuration.stage === '') {
      return STAGE.PRODUCTION;
    }

    if (this.configuration.stage.toLowerCase() === 'localhost') {
      return STAGE.LOCALHOST;
    }

    if (this.configuration.stage.toLowerCase() === 'integration') {
      return STAGE.INTEGRATION;
    }

    return STAGE.DEVELOP;
  }

  getPortalMode(): PORTALMODE {
    if (window
      && 'location' in window
      && 'host' in window.location && (window.location.host.startsWith('app.') || window.location.host.startsWith('localhost'))) {

      return PORTALMODE.CPO;
    }

    if (!this.configuration.portalMode || this.configuration.portalMode === '' || this.configuration.portalMode.toLowerCase() === 'szo') {
      return PORTALMODE.SZO;
    }

    if (this.configuration.portalMode.toLowerCase() === 'cpo') {
      return PORTALMODE.CPO;
    }
  }

  getAuthUrl() {
    return this.configuration.authUrl;
  }

  getShowSwaggerLinks() {
    return this.configuration.showSwaggerLinks;
  }

  getMdsApiUrl() {
    return this.configuration.mdsApiUrl;
  }

  getMmsApiUrl() {
    return this.configuration.mmsApiUrl;
  }

  getEesApiUrl() {
    return this.configuration.eesApiUrl;
  }

  getWssApiUrl() {
    return this.configuration.wssApiUrl;
  }

  getFhsApiUrl() {
    return this.configuration.fhsApiUrl;
  }

  getAmsApiUrl() {
    return this.configuration.amsApiUrl;
  }

  getPssApiUrl() {
    return this.configuration.pssApiUrl;
  }

  getOpsApiUrl() {
    return this.configuration.opsApiUrl;
  }

  getUcsApiUrl() {
    return this.configuration.ucsApiUrl;
  }
  getTrsApiUrl() {
    return this.configuration.trsApiUrl;
  }

  getVwsApiUrl() {
    return this.configuration.vwsApiUrl;
  }

  getNnsApiUrl() {
    return this.configuration.nnsApiUrl;
  }

  getPtsApiUrl() {
    return this.configuration.ptsApiUrl;
  }

  getqmsApiUrl() {
    return this.configuration.qmsApiUrl;
  }

  getMpsApiUrl() {
    return this.configuration.mpsApiUrl;
  }

  getTgsApiUrl() {
    return this.configuration.tgsApiUrl;
  }

  getRgsApiUrl() {
    return this.configuration.rgsApiUrl;
  }

  getDlqApiUrl() {
    return this.configuration.dlqApiUrl;
  }

  getFctApiUrl() {
    return this.configuration.fctApiUrl;
  }

  getSasApiUrl() {
    return this.configuration.sasApiUrl;
  }


  getClientId() {
    return this.configuration.clientId;
  }

  getVersion() {
    return this.configuration.version;
  }

  getTracerLink(traceId: string, requestTime: string) {
    if (!this.configuration.tracerBaseUrl) {
      return;
    }

    const timestampBegin = moment(requestTime).subtract(10, 'minutes').unix() * 1000;
    const timestampEnd = moment(requestTime).add(10, 'minutes').unix() * 1000;

    const stage = this.configuration.stage === 'develop' ? 'development' : this.configuration.stage;

    const stageDatabase = 'log-vw-mqs-' + stage;

    const url = this.configuration.tracerBaseUrl + '/explore?left=["' + timestampBegin + '","' + timestampEnd
      + '","' + stageDatabase
      + '",{"refId":"A","expr":"{namespace=\\"cpp\\"} |= \\"' + traceId + '\\""}]';


    return url;
  }

  // Deprecated
  getTracerBaseUrl() {
    return this.configuration.tracerBaseUrl;
  }

  getStage() {
    return this.configuration.stage;
  }

  getUserRole() {
    return this.configuration.userRole;
  }

  getQaUserRole() {
    return this.configuration.qaUserRole;
  }

  getAdminUserRole() {
    return this.configuration.adminUserRole;
  }

  getBetaUserRole() {
    return this.configuration.betaUserRole;
  }

  setApplicationCredentials(appName: string, appToken: string) {
    if (appName && appToken) {
      LocalStorageManagerService.setItem('appName', appName);
      LocalStorageManagerService.setItem('appToken', appToken);
    }
  }

  getApplicationToken() {
    const appName = LocalStorageManagerService.getItem('appName');
    const appToken = LocalStorageManagerService.getItem('appToken');

    if (appName && appToken) {
      return btoa(appName + ':' + appToken);
    }
  }

  getApplicationName() {
    return LocalStorageManagerService.getItem('appName');
  }

  removeApplicationCredentials() {
    LocalStorageManagerService.removeItem('appName');
    LocalStorageManagerService.removeItem('appToken');
  }

  private initViewWideMonitoring() {
    this.viewSizeService.viewWide$.subscribe(size => {

      ConfigService.viewWide.next(size);
      this.currentViewWide = size;
      this.toggleBigMode();
    });
  }

  private initKioskModeMonitoring() {
    this.kioskModeService.kioskMode$.subscribe(mode => {
      ConfigService.kioskMode.next(mode);
      this.currentKioskMode = mode;
      this.toggleBigMode();
    });
  }

  public getLdsApiUrl() {
    return this.configuration.ldsApiUrl;
  }
}

export enum PORTALMODE {
  SZO = 'SZO',
  CPO = 'CPO'
}
