import {Injectable } from '@angular/core';
import {EffectiveUserClaim} from './enterprise-role-provider.service';
import {KeyValue} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class Claims {

  private static claims = new  Array<KeyValue<string, EffectiveUserClaim>>();
  public static getRequiredClaim(name: string):  KeyValue<string, EffectiveUserClaim> {
    if (Claims.claims.length < 1) {
      Claims.init();
    }

    const foundClaim =  Claims.claims.find(c => c.key === name);

    if (foundClaim) {
      return foundClaim;
    } else {
      return null;
    }

  }

  private static init() {
    Claims.claims.push(
      { key: 'action-measurement-collect', value: new EffectiveUserClaim('actions-measurementsexport-collect', false) },
      { key: 'action-measurement-export', value: new EffectiveUserClaim('actions-measurementsexport-export', false) },

      { key: 'action-process-data-collect', value: new EffectiveUserClaim('actions-processdata-collect', false) },
      { key: 'action-process-data-export', value: new EffectiveUserClaim('actions-processdata-export', false) },

      { key: 'action-parent-search-collect', value: new EffectiveUserClaim('actions-parentparts-search', false) },
      { key: 'action-parent-search-export', value: new EffectiveUserClaim('actions-parentparts-export', false) },

      { key: 'action-assembly-search-collect', value: new EffectiveUserClaim('actions-partsexport-collect', false) },
      { key: 'action-assembly-search-export', value: new EffectiveUserClaim('actions-partsexport-export', false) },

      { key: 'action-engine-search-collect', value: new EffectiveUserClaim('actions-engineexport-collect', false) },
      { key: 'action-engine-search-export', value: new EffectiveUserClaim('actions-engineexport-export', false) },

      { key: 'action-stack-search-collect', value: new EffectiveUserClaim('actions-stack-collect', false) },
      { key: 'action-stack-search-export', value: new EffectiveUserClaim('actions-stack-export', false) },


      { key: 'action-tool-correction-list', value: new EffectiveUserClaim('actions-toolcorrection-list', false) },
      { key: 'action-tool-correction-filter', value: new EffectiveUserClaim('actions-toolcorrection-filter', false) },
      { key: 'action-tool-correction-necessary', value: new EffectiveUserClaim('actions-toolcorrection-correct', true) },
      { key: 'action-tool-correction-release', value: new EffectiveUserClaim('actions-toolcorrection-correcture-release', true) },
      { key: 'action-tool-correction-select-measurement-protocol', value: new EffectiveUserClaim('actions-toolcorrection-measurementprotocol-choose', true) },
      { key: 'action-tool-correction-export', value: new EffectiveUserClaim('actions-toolcorrection-export', false) },

      { key: 'action-filter-list', value: new EffectiveUserClaim('actions-filter-list-show', false) },
      { key: 'action-filter-edit', value: new EffectiveUserClaim('actions-filter-create', true) },

      /**
       * TODO: Fix functional resource mapping!
       */
      { key: 'action-build-program-planning-list', value: new EffectiveUserClaim('actions-filter-list-show', false) },


      { key: 'action-blockings-create', value: new EffectiveUserClaim('actions-blockings-create', true) },
      { key: 'action-blockings-list', value: new EffectiveUserClaim('actions-blockings-list', false) },
      { key: 'action-blockings-release', value: new EffectiveUserClaim('actions-blockings-release', true) },

      { key: 'action-qa-console-list', value: new EffectiveUserClaim('actions-qaconsole-blockingactions-search', true) },

      { key: 'engine-list', value: new EffectiveUserClaim('engines-list-search', false) },
      { key: 'engine-list-search', value: new EffectiveUserClaim('engines-list-search', false) },
      { key: 'engine-list-export', value: new EffectiveUserClaim('engines-list-export', false) },
      { key: 'engine-detail', value: new EffectiveUserClaim('engines-details-show', false) },

      { key: 'engine-label-generate', value: new EffectiveUserClaim('engine-label-generate', false) },
      { key: 'engine-label-print', value: new EffectiveUserClaim('engine-label-print', true) },
      { key: 'engine-label-export-pdf', value: new EffectiveUserClaim('engine-label-export-pdf', false) },
      { key: 'engine-label-keycode-change-and-print', value: new EffectiveUserClaim('engine-label-keycode-change-and-print', true) },

      { key: 'engine-offsets-read', value: new EffectiveUserClaim('engine-offsets', false) },
      { key: 'engine-offsets-write', value: new EffectiveUserClaim('engine-offsets', true) },

      // { key: 'part-list', value: new EffectiveUserClaim('home-dashboard', false) },
      { key: 'part-list-search', value: new EffectiveUserClaim('parts-list-search', false) },
      { key: 'part-list-export', value: new EffectiveUserClaim('parts-list-export', false) },
      { key: 'part-detail', value: new EffectiveUserClaim('parts-details-show', false) },

      { key: 'home-dashboard', value: new EffectiveUserClaim('home-dashboard', false) },
      { key: 'home-bigScreen-show', value: new EffectiveUserClaim('home-bigscreen-show', false) },

      { key: 'tools-dmc-parse', value: new EffectiveUserClaim('tools-dmcanalyse-parse', false) },
      { key: 'tools-dmc-parse-bulk', value: new EffectiveUserClaim('tools-dmcanalyse-parse', false) },
      { key: 'tools-dmc-parse-export', value: new EffectiveUserClaim('tools-dmcanalyse-export', false) },

      { key: 'tools-label-scan-camera', value: new EffectiveUserClaim('tools-labelscan-input', false) },
      { key: 'tools-label-scan-camera-export', value: new EffectiveUserClaim('tools-labelscan-export', false) },

      { key: 'tools-stack-check', value: new EffectiveUserClaim('tools-labelscan-input', false) },
      { key: 'tools-entity-check', value: new EffectiveUserClaim('tools-labelscan-input', false) },

      { key: 'tools-label-scan-text', value: new EffectiveUserClaim('tools-labelscanext-input', false) },
      { key: 'tools-label-scan-text-export', value: new EffectiveUserClaim('tools-labelscanext-export', false) },

      { key: 'sfm-board-read', value: new EffectiveUserClaim ('sfmboard-', false) },
      { key: 'sfm-board-set-status', value: new EffectiveUserClaim('sfmboard-status-update', true) },
      { key: 'sfm-board-show-links', value: new EffectiveUserClaim('sfmboard-more', false) },

      { key: 'news-show', value: new EffectiveUserClaim('news-show', false) },
      { key: 'news-create', value: new EffectiveUserClaim('news-create', true) },
      { key: 'news-download', value: new EffectiveUserClaim('news-download', true) },

      { key: 'metadata-shift-plans-export', value: new EffectiveUserClaim('metadata-workshift-export', false) },
      { key: 'metadata-shift-plans-list', value: new EffectiveUserClaim('metadata-workshift-filter', false) },

      { key: 'metadata-product-unit-list', value: new EffectiveUserClaim('metadata-productunit-create_update', false) },
      { key: 'metadata-product-unit-edit', value: new EffectiveUserClaim('metadata-productunit-create_update', true) },

      { key: 'metadata-product-center-list', value: new EffectiveUserClaim('metadata-productcenter-create_update', false) },
      { key: 'metadata-product-center-edit', value: new EffectiveUserClaim('metadata-productcenter-create_update', true) },

      { key: 'metadata-part-numbers-list', value: new EffectiveUserClaim('metadata-partnumber-filter', false) },
      { key: 'metadata-part-numbers-export', value: new EffectiveUserClaim('metadata-partnumber-export', false) },

      { key: 'metadata-cost-centers-list', value: new EffectiveUserClaim('metadata-costcenter-list', false) },
      { key: 'metadata-cost-centers-edit', value: new EffectiveUserClaim('metadata-costcenter-list', true) },

      { key: 'metadata-cost-center-profile-shift-management', value: new EffectiveUserClaim('metadata-costcenter-workshift_plan', false) },
      { key: 'metadata-cost-center-profile-shift-management-edit', value: new EffectiveUserClaim('metadata-costcenter-workshift_plan', true) },


      { key: 'metadata-cost-center-profile-interface-management', value: new EffectiveUserClaim('metadata-costcenter-external_system_interfaces', false) },
      { key: 'metadata-cost-center-profile-interface-management-edit', value: new EffectiveUserClaim('metadata-costcenter-external_system_interfaces', true) },
      { key: 'metadata-cost-center-profile-target-values-management', value: new EffectiveUserClaim('metadata-costcenter-target_management', false) },
      { key: 'metadata-cost-center-profile-target-values-management-edit', value: new EffectiveUserClaim('metadata-costcenter-target_management', true) },
      { key: 'metadata-cost-center-profile-audit-infos', value: new EffectiveUserClaim('metadata-costcenter-audit_infos', false) },

      { key: 'metadata-cost-center-profile-process-settings', value: new EffectiveUserClaim('metadata-costcenter-', false) },
      { key: 'metadata-cost-center-profile-process-settings-cost-center-deactivate', value: new EffectiveUserClaim('metadata-costcenter-deactivate', true) },

      { key: 'metadata-cost-center-profile-bigScreen-settings', value: new EffectiveUserClaim('metadata-costcenter-bigscreen-', false) },
      { key: 'metadata-cost-center-profile-bigScreen-settings-edit', value: new EffectiveUserClaim('metadata-costcenter-bigscreen-style-change', true) },
      { key: 'metadata-cost-center-profile-bigScreen-goals-deviation', value: new EffectiveUserClaim('metadata-costcenter-bigscreen-goals-deviation', true) },
      { key: 'metadata-cost-center-profile-bigScreen-refresh', value: new EffectiveUserClaim('metadata-costcenter-bigscreen-refresh', true) },

      { key: 'metadata-cost-center-profile-infoSystem-settings', value: new EffectiveUserClaim('metadata-costcenter-infosystem-', false) },
      { key: 'metadata-cost-center-profile-infoSystem-settings-alert-duration', value: new EffectiveUserClaim('metadata-costcenter-infosystem-create', true) },
      { key: 'metadata-cost-center-profile-infoSystem-settings-support-duration', value: new EffectiveUserClaim('metadata-costcenter-infosystem-create', true) },

      { key: 'metadata-cost-center-profile-tool-correction-settings', value: new EffectiveUserClaim('actions-toolcorrection-', false) },
      { key: 'metadata-cost-center-profile-tool-correction-settings-warn-on-yellow', value: new EffectiveUserClaim('actions-toolcorrection-', true) },

      { key: 'metadata-cost-center-profile-name-edit', value: new EffectiveUserClaim('metadata-costcenter-pcpe-modify', true) },
      { key: 'metadata-cost-center-profile-product-center-edit', value: new EffectiveUserClaim('metadata-costcenter-pcpe-modify', true) },
      { key: 'metadata-cost-center-profile-product-unit-edit', value: new EffectiveUserClaim('metadata-costcenter-pcpe-modify', true) },

      { key: 'metadata-cost-center-profile-quickView-oeeGoal-settings', value: new EffectiveUserClaim('metadata-costcenter-oee_goals', false) },
      { key: 'metadata-cost-center-profile-quickView-oeeGoal-settings-edit', value: new EffectiveUserClaim('metadata-costcenter-oee_goals', true) },

      { key: 'metadata-cost-center-profile-quickView-cycleTime-settings', value: new EffectiveUserClaim('metadata-costcenter-cycletime', false) },
      { key: 'metadata-cost-center-profile-quickView-cycleTime-settings-edit', value: new EffectiveUserClaim('metadata-costcenter-cycletime', true) },

      { key: 'metadata-cost-center-profile-quickView-net-production-time-settings', value: new EffectiveUserClaim('metadata-costcenter-net_production_time', false) },
      { key: 'metadata-cost-center-profile-quickView-net-production-time-settings-edit', value: new EffectiveUserClaim('metadata-costcenter-net_production_time', true) },

      { key: 'metadata-cost-center-profile-quickView-go-target-settings', value: new EffectiveUserClaim('metadata-costcenter-go_goals', false) },
      { key: 'metadata-cost-center-profile-quickView-go-target-settings-edit', value: new EffectiveUserClaim('metadata-costcenter-go_goals', true) },

      { key: 'metadata-cost-center-profile-quickView-go-oee-settings', value: new EffectiveUserClaim('metadata-costcenter-go-oees', false) },

      { key: 'tracking-stacking-app-submit', value: new EffectiveUserClaim('tracking-loading-loading-close', true) },
      { key: 'tracking-stacking-app-show', value: new EffectiveUserClaim('tracking-loading-loading-close', false) },
      { key: 'tracking-warehouse-list', value: new EffectiveUserClaim('tracking-warehouse-list', false) },

      { key: 'tracking-stack-detail', value: new EffectiveUserClaim('tracking-activestacks-show', false) },
      { key: 'tracking-events-export', value: new EffectiveUserClaim('tracking-events-export', false) },

      { key: 'tracking-events-list', value: new EffectiveUserClaim('tracking-events-filter', false) },
      { key: 'tracking-daily-program-list', value: new EffectiveUserClaim('tracking-dailyprogram-filter', false) },
      { key: 'tracking-stack-list', value: new EffectiveUserClaim('tracking-activestacks-show', false) },

      { key: 'statistics-quickView', value: new EffectiveUserClaim('statistics-quickview', false) },
      { key: 'statistics-oee', value: new EffectiveUserClaim('statistics-oee', false) },
      { key: 'statistics-output', value: new EffectiveUserClaim('statistics-output', false) },
      { key: 'statistics-workload', value: new EffectiveUserClaim('statistics-workload', false) },

      { key: 'infos-inbox', value: new EffectiveUserClaim('basic-user-permissions', false) },

      { key: 'feat-statistics', value: new EffectiveUserClaim('feat-statistics', false) },
      { key: 'feat-register', value: new EffectiveUserClaim('feat-register', true) },
      { key: 'feat-admin', value: new EffectiveUserClaim('feat-admin', true) },

      { key: 'usecase-read', value: new EffectiveUserClaim('usecase-read', false) },

      { key: 'system-service-monitor', value: new EffectiveUserClaim('system-servicemonitor-filter', false) }
    )
  }
}
