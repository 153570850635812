import {Configuration as EesConfiguration} from '@cstx/volkswagen-mqs-engine-service-client';
import {Configuration as TgsConfiguration} from '@cstx/volkswagen-mqs-tracking-service-client';
import {Configuration as MdsConfiguration} from '@cstx/volkswagen-mqs-metadata-service-client';
import {Configuration as MmsConfiguration} from '@cstx/volkswagen-mqs-manufacturing-monitor-service-client';
import {Configuration as WssConfiguration} from '@cstx/volkswagen-mqs-workshift-service-client';
import {Configuration as FhsConfiguration} from '@cstx/volkswagen-mqs-file-handling-service-client';
import {Configuration as AmsConfiguration} from '@cstx/volkswagen-mqs-application-management-service-client';
import {Configuration as PtsConfiguration} from '@cstx/volkswagen-mqs-part-service-client';
import {Configuration as PssConfiguration} from '@cstx/volkswagen-mqs-parsing-service-client';
import {Configuration as NnsConfiguration} from '@cstx/volkswagen-mqs-notification-service-client';
import {Configuration as MpsConfiguration} from '@cstx/volkswagen-mqs-work-sequence-service-client';
import {Configuration as QmsConfiguration} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {Configuration as DlqConfiguration} from '@cstx/volkswagen-mqs-dead-letter-queue-service-client';
import {Configuration as TrsConfiguration} from '@cstx/volkswagen-mqs-telegram-receiver-service-client';
import {Configuration as VwsConfiguration} from '@cstx/volkswagen-mqs-virtual-warehouse-service-client';
import {Configuration as RgsConfiguration} from '@cstx/volkswagen-mqs-report-service-client';
import {Configuration as OpsConfiguration} from '@cstx/volkswagen-mqs-onpremise-procedure-service-client';
import {Configuration as UcsConfiguration} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {Configuration as FctConfiguration} from '@cstx/volkswagen-mqs-faulty-component-tracking-service-client';
import {Configuration as SasConfiguration} from '@cstx/volkswagen-mqs-shopfloor-assets-service-client';
import {Configuration as LdsConfiguration} from '@cstx/volkswagen-mqs-logistics-data-service-client';
import {OAuthService} from 'angular-oauth2-oidc';
import {ConfigService} from '../../core/services/config.service';

export const PTS_CONFIG_PROVIDER = {
  provide: PtsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new PtsConfiguration(getConfigurationParameters(configService.getPtsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const PSS_CONFIG_PROVIDER = {
  provide: PssConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new PssConfiguration(getConfigurationParameters(configService.getPssApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const OPS_CONFIG_PROVIDER = {
  provide: OpsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new OpsConfiguration(getConfigurationParameters(configService.getOpsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const UCS_CONFIG_PROVIDER = {
  provide: UcsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new UcsConfiguration(getConfigurationParameters(configService.getUcsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const TRS_CONFIG_PROVIDER = {
  provide: TrsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new TrsConfiguration(getConfigurationParameters(configService.getTrsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const VWS_CONFIG_PROVIDER = {
  provide: VwsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new VwsConfiguration(getConfigurationParameters(configService.getVwsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const NNS_CONFIG_PROVIDER = {
  provide: NnsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new NnsConfiguration(getConfigurationParameters(configService.getNnsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};
export const MPS_CONFIG_PROVIDER = {
  provide: MpsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new MpsConfiguration(getConfigurationParameters(configService.getMpsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};
export const QMS_CONFIG_PROVIDER = {
  provide: QmsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new QmsConfiguration(getConfigurationParameters(configService.getqmsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};
export const MDS_CONFIG_PROVIDER = {
  provide: MdsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new MdsConfiguration(getConfigurationParameters(configService.getMdsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};
export const MMS_CONFIG_PROVIDER = {
  provide: MmsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new MmsConfiguration(getConfigurationParameters(configService.getMmsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};
export const WSS_CONFIG_PROVIDER = {
  provide: WssConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new WssConfiguration(getConfigurationParameters(configService.getWssApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};
export const FHS_CONFIG_PROVIDER = {
  provide: FhsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new FhsConfiguration(getConfigurationParameters(configService.getFhsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};
export const AMS_CONFIG_PROVIDER = {
  provide: AmsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new AmsConfiguration(getConfigurationParameters(configService.getAmsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};
export const EES_CONFIG_PROVIDER = {
  provide: EesConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new EesConfiguration(getConfigurationParameters(configService.getEesApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const TGS_CONFIG_PROVIDER = {
  provide: TgsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new TgsConfiguration(getConfigurationParameters(configService.getTgsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const DLQ_CONFIG_PROVIDER = {
  provide: DlqConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new DlqConfiguration(getConfigurationParameters(configService.getDlqApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const RGS_CONFIG_PROVIDER = {
  provide: RgsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new RgsConfiguration(getConfigurationParameters(configService.getRgsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const FCT_CONFIG_PROVIDER = {
  provide: FctConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new FctConfiguration(getConfigurationParameters(configService.getFctApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const SAS_CONFIG_PROVIDER = {
  provide: SasConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new SasConfiguration(getConfigurationParameters(configService.getSasApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

export const LDS_CONFIG_PROVIDER = {
  provide: LdsConfiguration,
  useFactory: (oAuthService: OAuthService, configService: ConfigService) =>
    new LdsConfiguration(getConfigurationParameters(configService.getLdsApiUrl(), oAuthService, configService.getApplicationToken())),
  deps: [OAuthService, ConfigService],
  multi: false
};

function getConfigurationParameters(apiUrl: string, oAuthService: OAuthService, appToken: string) {
  if (appToken) {
    return {
      basePath: apiUrl,
      credentials: {
        basicAuth: appToken
      }
    };
  } else {
    return {
      basePath: apiUrl,
      credentials: {
        'bearer-jwt': oAuthService.getAccessToken.bind(oAuthService)
      }
    };
  }


}
