import {
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
} from '@angular/core';
import {User} from '../models/user';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {ConfigService} from '../services/config.service';
import {
  ThemeSelection,
  ThemeType,
  ThemingCategory,
  ThemingService,
  ThemingSetting
} from '../../shared/services/theming.service';
import {Observable, Subject} from 'rxjs';
import {LocalStorageManagerService} from '../../shared/services/local-storage-manager.service';
import {STAGE} from '../models/stage';
import {EnterpriseRoleProviderService} from '../services/enterprise-role-provider.service';

declare let $: any;

@Component({
  selector: 'op-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  @Input() currentUser: User;
  @Input() loggedIn = false;

  @Output() onSidebarCollapsed = new EventEmitter<boolean>();


  public set sidebarCollapsed(value: boolean) {
    this._sidebarCollapsed = value;

    this.onSidebarCollapsed.emit(this._sidebarCollapsed);
    LocalStorageManagerService.setItem('sidebarCollapsed', String(this._sidebarCollapsed));
  }

  public get sidebarCollapsed(): boolean {
    return this._sidebarCollapsed;
  }

  public stageEnum: STAGE;
  public themingSource: Observable<ThemingSetting>;
  public selectedThemeSource: Observable<ThemeSelection>

  private stop$: Subject<void> = new Subject<void>();
  private unfoldedElement: string;
  private breakpointState: BreakpointState;
  private _sidebarCollapsed = false;

  protected readonly STAGE = STAGE;
  protected readonly EnterpriseRoleProviderService = EnterpriseRoleProviderService;


  constructor(private breakpointObserver: BreakpointObserver,
              public configService: ConfigService,
              private themingService: ThemingService) {


    this.stageEnum = this.configService.getStageEnum();

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        this.breakpointState = state;
        if (state.matches) {
          this.sidebarCollapsed = true;

        } else {
          this.sidebarCollapsed =  LocalStorageManagerService.getItem('sidebarCollapsed') === 'true';
        }
      });

      this.themingSource = this.themingService.getThemingSource();
      this.selectedThemeSource = this.themingService.getSelectionSource();
  }

  public ngOnDestroy(): void {
    this.stop$.next();
  }

  public ngOnInit(): void {
    if (!this.breakpointState.matches) {
      this.sidebarCollapsed =  LocalStorageManagerService.getItem('sidebarCollapsed') === 'true';
    } else {
      this.sidebarCollapsed = true;
    }
  }

  public onSideBarToggle() {
    this.sidebarCollapsed = !this._sidebarCollapsed;
  }

  public collapseOther(unfolded: string) {
    if (this.unfoldedElement !== 'settingsSubmenu' && this.unfoldedElement === unfolded) {
      return;
    }

    if (unfolded === 'adminSubmenu') {
      $('#adminSubmenu').collapse('show');
      $('#adminSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#adminSubmenuLink').attr('aria-expanded', 'false');
      $('#adminSubmenu').collapse('hide');
    }

    if (unfolded === 'toolsSubmenu') {
      $('#toolsSubmenu').collapse('show');
      $('#toolsSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#toolsSubmenu').toggleClass('show', false);
      $('#toolsSubmenuLink').attr('aria-expanded', 'false');
    }

    if (unfolded === 'statisticsSubmenu') {
      $('#statisticsSubmenu').collapse('show');
      $('#statisticsSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#statisticsSubmenu').toggleClass('show', false);
      $('#statisticsSubmenuLink').attr('aria-expanded', 'false');
    }

    if (unfolded === 'metadataSubmenu') {
      $('#metadataSubmenu').collapse('show');
      $('#metadataSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#metadataSubmenu').toggleClass('show', false);
      $('#metadataSubmenuLink').attr('aria-expanded', 'false');
    }

    if (unfolded === 'tracingSubmenu') {
      $('#tracingSubmenu').collapse('show');
      $('#tracingSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#tracingSubmenu').toggleClass('show', false);
      $('#tracingSubmenuLink').attr('aria-expanded', 'false');
    }

    if (unfolded === 'actionSubmenu') {
      $('#actionSubmenu').collapse('show');
      $('#actionSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#actionSubmenu').toggleClass('show', false);
      $('#actionSubmenuLink').attr('aria-expanded', 'false');
    }

    if (unfolded === 'usecaseSubmenu') {
      $('#usecaseSubmenu').collapse('show');
      $('#usecaseSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#usecaseSubmenu').toggleClass('show', false);
      $('#usecaseSubmenuLink').attr('aria-expanded', 'false');
    }
    if (unfolded === 'fctSubmenu') {
      $('#fctSubmenu').collapse('show');
      $('#fctSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#fctSubmenu').toggleClass('show', false);
      $('#fctSubmenuLink').attr('aria-expanded', 'false');
    }
    if (unfolded === 'settingsSubmenu' && !$('#settingsSubmenu').hasClass('show')) {
      $('#settingsSubmenu').collapse('show');
      $('#settingsSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#settingsSubmenu').collapse('hide');
      $('#settingsSubmenuLink').attr('aria-expanded', 'false');
    }
    if (unfolded === 'assetSubmenu') {
      $('#assetSubmenu').collapse('show');
      $('#assetSubmenuLink').attr('aria-expanded', 'true');
      this.unfoldedElement = unfolded;
    } else {
      $('#assetSubmenu').toggleClass('show', false);
      $('#assetSubmenuLink').attr('aria-expanded', 'false');
    }
  }

  public setThemingOption(category: ThemingCategory, type: ThemeType, value: string) {
    this.themingService.setTheme(category, type, value);
  }

  public getThemingOption(selection: any, themingCategory: ThemingCategory, type: ThemeType, value: string) {
    return (selection
      && selection[themingCategory]
      && selection[themingCategory][type] === value);
  }
}
