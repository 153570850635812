import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LabelEncoderControllerV2Service, LabelType} from '@cstx/volkswagen-mqs-report-service-client';
import {DomSanitizer} from '@angular/platform-browser';
import {ErrorHandler} from '../../services/error-handler/error-handler';

@Component({
  selector: 'op-entity-label',
  templateUrl: './entity-label.component.html',
  styleUrls: ['./entity-label.component.scss']
})
export class EntityLabelComponent implements OnInit {
  @Input() value: string;
  @Input() labelType: string;
  @Input() width = '60';
  @Input() height = '60';
  @Input() showSpinner = false;
  @Input() valueHasCheckDigit: boolean;

  @Output() onImageLoaded = new EventEmitter<any>();

  image: any;
  isLoading: boolean;
  currentLabelType: LabelType;
  public displayNotification = false;


  constructor(private labelEncoderService: LabelEncoderControllerV2Service, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.generateLabel();
  }

  private generateLabel() {
      if (this.labelType === undefined || this.labelType === null) {
        return;
      } else {
        if (this.labelType.toUpperCase() === 'DMC') {
          this.currentLabelType = LabelType.Dmc;
        } else if (this.labelType.toUpperCase() === 'MBK') {
          this.currentLabelType = LabelType.Mbk;
        } else if (this.labelType.toUpperCase() === 'GTL') {
          this.currentLabelType = LabelType.Gtl;
        } else if (this.labelType.toUpperCase() === 'URL') {
          this.currentLabelType = LabelType.Url;
        } else {
          this.currentLabelType = LabelType.Engine;
        }
      }

      if (this.value === undefined || this.value === null) {
        return;
      }

      this.isLoading = true;
      this.labelEncoderService.getEncodedLabelImageV2({
        labelType: this.currentLabelType,
        value: this.value,
        valueContainsCheckDigit: this.valueHasCheckDigit
        }
      ).toPromise()
        .then(blob => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(blob);

          fileReader.onloadend = () => {
            if (typeof fileReader.result === 'string') {
              this.image = this.sanitizer.bypassSecurityTrustUrl(fileReader.result);
              this.onImageLoaded.emit(fileReader.result);
            }
          };

          setTimeout(() => {
            if (this.isLoading) {
              fileReader.abort();
            }
          }, 5000);
          }
        ).catch(error => {
          ErrorHandler.printError(error);
      }).finally(() => {
          this.isLoading = false;
        });
  }

  copyToClipboard() {
    this.displayNotification = true;
    setTimeout(() => this.displayNotification = false, 3000);
    navigator.clipboard.writeText(this.value);
  }
}
