import {BlockingActionStatistic} from './blockingActionStatistic';
import {
  BlockedComponentResponse,
  BlockingActionCreateRequest,
  BlockingActionResponse,
  BlockingActionState,
  BlockingActionUpdateRequest,
  BlockingType,
  ComponentStatus,
  ComponentType,
  CreateBlockingActionV2RequestParams
} from '@cstx/volkswagen-mqs-quality-management-service-client';
import {AuditHistoryEntry} from './auditHistoryEntry';
import {EngineResponse} from '@cstx/volkswagen-mqs-engine-service-client';
import {PartService} from '../part.service';
import {Part} from './part';
import {TrackingService} from '../tracking.service';
import {StackResponse} from '@cstx/volkswagen-mqs-tracking-service-client';
import {
  ReleaseBlockedComponentsRequestParams
} from '@cstx/volkswagen-mqs-quality-management-service-client/api/blockingActionsControllerV2.service';
import {ComponentCacheService} from '../../component-cache.service';

export class BlockingAction {
  id?: string;
  blockingActionNr?: string;
  description?: string;

  componentIds?: string[];
  componentIdentifiers?: string[];


  componentIdsExtension?: string[];
  componentIdentifiersExtension?: string[];



  releasedComponentIds?: string[];

  state?: BlockingActionState;

  blockedAt?: string;
  blockingReason: string;
  blockedBy?: string;

  releasedAt?: string;
  releaseReason?: string;
  releasedBy?: string;

  statistic?: BlockingActionStatistic;

  type?: BlockingType;
  blockingFilterId?: string;

  untranslatedComponentNames?: string[]

  public assignedComponents = new Array<BlockedComponentResponse>();
  public blockingServiceRequestType?: BlockingServiceRequestType | null;
  public requestFailed: boolean;
  public history: Array<AuditHistoryEntry> = new Array<AuditHistoryEntry>();

  public engines: Array<EngineResponse>;
  public parts: Array<Part>;
  public stacks: Array<StackResponse>;
  public shippedStacks: Array<StackResponse>;

  public componentsFile: any;
  public componentsFileHasHeader: boolean = undefined;
  public componentsFileColumnIndex: number = undefined;

  public filterId = '00000000-0000-0000-0000-000000000000'; // ID of the filter entity which is the source of the blockingAction
  public componentReleaseReasons = new Array<string>();

  public trackingInfoLoadingState: TrackingInfoLoadingState = TrackingInfoLoadingState.NOT_LOADED;
  public assignedComponentsLoaded = false;

  constructor(response: BlockingActionResponse = null) {
    this.blockingServiceRequestType = null;

    if (response) {
        this.id = response.id;

        this.blockedAt = response.blockedAt;
        this.blockingReason = response.blockingReason;

        this.releasedAt = response.releasedAt;
        this.releaseReason = response.releaseReason;

        // TODO: Replace with backend releasedBy
        this.releasedBy = response.releasedAt ? response.modifiedBy : undefined;

        this.state = response.blockingActionState;

        // this.type = response.type;
        this.blockingActionNr = response.humanReadableId;

        // this.blockingFilterId = response.blockingFilterId;
        this.componentIds = response.componentIds;

        // TODO: Replace with backend blockedBy
        this.blockedBy = response.createdBy;
        this.description = response.description;
        this.componentReleaseReasons = Array.from(response.componentReleaseReasons.values());

        this.filterId = response.filterId;

        this.untranslatedComponentNames = response.untranslatedComponentNames
    }
  }

  public getCreateBlockingActionV2Request(): CreateBlockingActionV2RequestParams {
    return {
      // type: this.type,
      action: this.blockingReason,
      blockingReason: this.blockingReason,
      description: this.description,
      componentIdentifiers: this.componentIdentifiers,
      fileParseInputContent: this.componentsFile,
      fileParseInputColumnIndexOverride: this.componentsFileColumnIndex,
      fileParseInputIgnoreTopRowOverride: this.componentsFileHasHeader

    };
  }

  /**
   * Deprecated
   */
  public getBlockingActionCreateRequest(): BlockingActionCreateRequest {
    return {
      type: this.type,
      blockingReason: this.blockingReason,
      description: this.description,
      componentIds: this.componentIds ? this.componentIds : null
    };
  }


  public getBlockingActionUpdateRequest(): BlockingActionUpdateRequest {
    return  {
      releaseReason: this.releaseReason,
      id: this.id
    };
  }

  public getFilterLink(): string {
    if (this.filterId) {
      return 'actions/filter/edit/' + this.filterId;
    } else {
      return null;
    }
  }

  public getBlockingActionReleaseComponentsRequest(): ReleaseBlockedComponentsRequestParams {
    return {
      blockingActionId: this.id,
      releaseReason: this.releaseReason,
      componentIdentifiers: this.releasedComponentIds,
      fileParseInputContent: this.componentsFile,
      fileParseInputColumnIndexOverride: this.componentsFileColumnIndex,
      fileParseInputIgnoreTopRowOverride: this.componentsFileHasHeader

    }
  }

  public getEnginesInBlockingCount(): number {
      if (this.assignedComponents) {
          return this.assignedComponents.filter(c => c.componentType === ComponentType.Engine).length;
      }

      return 0;
  }

  public getEnginesInBlockingBlockedCount() : number {
      if (this.assignedComponents) {
          return this.assignedComponents.filter(c =>
            c.componentType === ComponentType.Engine && c.componentStatus !== ComponentStatus.Released).length;
      }

      return 0;
  }

  public getEnginesInBlockingReleasedCount() : number {
      if (this.assignedComponents) {
          return this.assignedComponents
              .filter(c => c.componentType === ComponentType.Engine
                  && c.componentStatus === ComponentStatus.Released ).length;
      }

      return 0;
  }

  public getEnginesShippedCount(): number {
    if (this.engines) {
      return this.engines.filter(e => e.engineState === 'SHIPPED').length;
    } else {
      return 0;
    }
  }

  public getEnginesStoredCount(): number {
    if (this.engines) {
      return this.engines.filter(e => e.engineState === 'STACKED').length;
    } else {
      return 0;
    }
  }

  public getEnginesNotShippedCount(): number {
    if (this.engines) {
      return this.engines.filter(e => e.engineState !== 'SHIPPED').length;
    } else {
      return 0;
    }
  }

  // TODO: Merge into blocking-actions-profile-service or combine in yet another service
  public async getEngines(cacheService: ComponentCacheService, trackingService: TrackingService = null) {
    const engineIds =
      this.assignedComponents.filter(c => c.componentType === ComponentType.Engine).map(c => c.componentId);

    this.engines = await cacheService.getEnginesWithCache(engineIds);
    this.trackingInfoLoadingState = TrackingInfoLoadingState.LOADED;

    if (trackingService) {
      const stacks =
        await cacheService.getStacksWithCache(this.engines.filter(e => e.engineState !== 'SHIPPED').map(e => e.id));

      const stackIdentifierSet = new Set(stacks.map(s => s.stackIdentifier));

      if (stackIdentifierSet.size > 0 ) {
        this.stacks = new Array<StackResponse>();

        stackIdentifierSet.forEach(i => {
          this.stacks.push(stacks.find(s => s.stackIdentifier === i));
        })
      }


      if (this.getEnginesShippedCount() > 0) {
        const shippedStacks =
          await cacheService.getStacksWithCache(this.engines.filter(e => e.engineState === 'SHIPPED').map(e => e.id));

        const shippedStackIdentifierSet = new Set(shippedStacks.map(s => s.stackIdentifier));

        if (shippedStackIdentifierSet.size > 0) {
            this.shippedStacks = new Array<StackResponse>();

            shippedStackIdentifierSet.forEach(i => {
              this.shippedStacks.push(shippedStacks.find(s => s.stackIdentifier === i));
            })
        }
      }
    }
  }

  // TODO: Merge into blocking-actions-profile-service or combine in yet another service
  public async getParts(partService: PartService) {
    const partIds =
      this.assignedComponents.filter(c => c.componentType === ComponentType.Part).map(c => c.componentId);

    this.parts = await partService.getPartByIDList(partIds);

    // TODO: Add get Stack info for already shipped parts
  }
}

export enum BlockingServiceRequestType {
  GET = 'GET',
  RELEASE = 'RELEASE',
  DELETE = 'DELETE',
  EXTEND = 'EXTEND',
  PARTIAL_RELEASE = 'PARTIAL_RELEASE',
  CREATE_FOR_FILTER = 'CREATE_FOR_FILTER'
}

export enum TrackingInfoLoadingState {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  NONE = 'NONE'
}
