import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';

export class EngineFilter extends FilterBase {
  public engineState?: 'UNKNOWN' | 'IN_PRODUCTION' | 'MANUFACTURED' | 'READY_TO_SHIP' | 'SHIPPED' | 'RETURNED';
  public engineBlockedState?: 'NONE' | 'BLOCKED';
  public engineCode: Array<string>  = [];
  public engineNumber: Array<string>  = [];
  public keyCode: Array<string>  = [];
  public costCenter:Array<string>  = [];
  public buildDateFrom?;
  public buildDateTo?;
  public partNumber: Array<string>  = [];
  public developmentNumber  = '';
  public slice: Slice;
  public orderNumber = '';
  public engineNumberFrom?: string;
  public engineNumberTo?: string;
  public engineIds:Array<string>  = [];
  public timingBeltLabel: string;

  constructor() {
    super();

    this.pagingSize = 50;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = ['modifiedAt,desc'];
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }

  public getAllParamsV2() {
    return {...this.getPageableParams(), ...this.getFilterParamsV2()};
  }

  public getFilterParams() {
    return {
      buildDateFrom: this.buildDateFrom,
      buildDateTo: this.buildDateTo,
      costCenter: this.costCenter.pop(),
      engineCode: this.engineCode.pop(),
      engineNumber: this.engineNumber,
      engineState: this.engineState,
      engineState2: this.engineState,
      engineState3: this.engineState,
      keyCode: this.keyCode,
      partNumber: this.partNumber,
      orderNumber: this.orderNumber,
      developmentNumber: this.developmentNumber,
      engineBlockedState: this.engineBlockedState,
      engineBlockedState2: this.engineBlockedState,
      engineBlockedState3: this.engineBlockedState,
    };
  }

  public getFilterParamsV2() {
    return {
      buildDateFrom: this.buildDateFrom,
      buildDateTo: this.buildDateTo,
      costCenter: this.costCenter.pop(),
      engineCode: this.engineCode.pop(),
      engineNumber: this.engineNumber.pop(),
      engineState: this.engineState,
      engineState2: this.engineState,
      engineState3: this.engineState,
      keyCode: this.keyCode.pop(),
      partNumber: this.partNumber.pop(),
      orderNumber: this.orderNumber,
      developmentNumber: this.developmentNumber,
      engineBlockedState: this.engineBlockedState,
      engineBlockedState2: this.engineBlockedState,
      engineBlockedState3: this.engineBlockedState,
      timingBeltLabel: this.timingBeltLabel
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }

  public IsEmpty(): boolean {
    if (this.engineState) {
      return false;
    }
    if (this.engineCode.length !== 1) {
      return false;
    }
    if (this.engineNumber.length !== 1) {
      return false;
    }
    if (this.keyCode.length !== 1) {
      return false;
    }
    if (this.costCenter.length !== 1) {
      return false;
    }
    if (this.buildDateFrom || this.buildDateTo) {
      return false;
    }
    if (this.partNumber.length !== 1) {
      return false;
    }
    if (this.developmentNumber.length !== 1) {
      return false;
    }

    if (this.orderNumber !== '') {
      return false;
    }
    return true;
  }
}
