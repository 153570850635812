import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {LoadingEventReceiverService} from '../../services/loading-event-receiver.service';
import {LoadingEvent} from '../../models/loadingEvent';
import {takeUntil} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'op-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnChanges, OnDestroy {
  @Input() path: string;
  @Input() lastItemAlias: string;
  @Input() itemAliases: string[];
  @Input() version2: boolean;
  @Input() pathSplitted: string[];
  @Input() loadingEventReceiverId: string;

  private unsub: Subject<boolean> = new Subject<boolean>();

  private linkAliases = [
    { link: 'admin', alias: 'admin/home'},
    { link: 'metadata', alias: 'metadata/home'},
    { link: 'tracking', alias: 'tracking/home'},
    { link: 'tools', alias: 'tools/home'},
    { link: 'engine', alias: 'engine/list'},
    { link: 'actions', alias: 'actions/home'},
    { link: 'statistik', alias: 'statistics/home'},
    { link: 'infos', alias: 'home/info/list' },
    { link: 'costcenter', alias: 'metadata/cost-center'},
    { link: 'assets', alias: 'asset'},
    { link: 'parts', alias: 'part'},
  ];

  public pathElements = new Array<string>();
  public pathElementsV2 = new Array<BreadCrumbElement>()
  public loadingEvent: LoadingEvent;

  constructor(private translateService: TranslateService,
              private title: Title) {
  }

  public ngOnInit(): void {
    this.initPathElements();
    if (this.version2) {
      this.translateService.onLangChange.pipe(takeUntil(this.unsub)).subscribe(() => {

        this.pathElementsV2.forEach(el => {
          if (el.translation) {
            el.translation = this.translateService.instant('breadcrumb.' + el.name.toLowerCase());
          }
        })

        const lastElement = this.pathElementsV2[this.pathElementsV2.length - 1];
        this.setAppTitle(lastElement);
      });
    }

    if (this.loadingEventReceiverId) {
      const eventReceiver = LoadingEventReceiverService.getEvent(this.loadingEventReceiverId);
      this.loadingEvent = eventReceiver;

      eventReceiver?.onChange.subscribe(() => {
        /**
         * TODO: React on the different stages.
         */
      })
    }
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.path) {
      this.initPathElements();
    }
  }

  public ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }


  public getRouterLink(i: number): string {
    let routerLink = '';

    this.pathElements.forEach((element, index) => {
      if (index !== 0 && index < i + 1) {

        if (i === 1) {
          routerLink =  routerLink + '/' + this.getLinkAlias(element.toLocaleLowerCase());
        } else {
          routerLink = routerLink + '/' + element.toLocaleLowerCase();
        }
      }
    });

    return routerLink;
  }

  private getRouterLinkV2(i: number): string {
    let routerLink = '';

    this.pathElementsV2.forEach((element, index) => {
      if (index !== 0 && index < i + 1) {

        if (i === 1) {
          routerLink =  routerLink + '/' + this.getLinkAlias(element.name.toLocaleLowerCase());
        } else {
          routerLink = routerLink + '/' + element.name.toLocaleLowerCase();
        }
      }
    });

    return routerLink;
  }
  private getLinkAlias(pathElement: string): string {
    const alias = this.linkAliases.find(a => a.link === pathElement);

    if (alias === undefined) { return pathElement; }
    return alias.alias;
  }
  private initPathElements() {
    if (this.path !== null && this.path !== undefined) {

      if(this.pathSplitted !== null && this.pathSplitted !== undefined && this.pathSplitted.length > 0) {
        this.pathElements = this.pathSplitted;
      } else {
        this.pathElements = this.path.split('\/');
      }

      const pathElementsV2 = new Array<BreadCrumbElement>();
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.pathElements.length; i++) {

        let value: string;
        if (i < this.pathElements.length - 1) {
           value = this.pathElements[i];
        } else {
          if (!this.lastItemAlias) {
            value = this.pathElements[i];
          } else {
            value = this.lastItemAlias;
          }
        }

        let translation = this.translateService.instant('breadcrumb.' + value.toLowerCase());
        if (translation === 'breadcrumb.' + value.toLowerCase()) {
          translation = undefined;
        }

        const breadCrumbElement = new BreadCrumbElement(value, i, this.getRouterLinkV2(i), translation);
        pathElementsV2.push(breadCrumbElement);
      }
      this.pathElementsV2 = pathElementsV2;

      const lastElement = this.pathElementsV2[this.pathElementsV2.length - 1];
      this.setAppTitle(lastElement);
    }
  }

  private setAppTitle(lastElement: BreadCrumbElement) {
    this.title.setTitle(lastElement.translation ? lastElement.translation : lastElement.name);
  }
}

export class BreadCrumbElement {
  public index: number;
  public name: string;
  public translation?: string;
  public link?: string;

  constructor(name: string, index: number, link?: string, translation?: string ) {
    this.name = name;
    this.translation = translation;
    this.link = link;
    this.index = index;
  }
}
