import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ConnectionsControllerService} from '@cstx/volkswagen-mqs-notification-service-client';
import {WebsocketsService} from '../services/websockets.service';
import {User} from '../models/user';
import {AuthService} from '../services/auth.service';
import {ConfigService} from '../services/config.service';
import {LoggingService} from '../logging/logging.service';
import {LogLevel} from '../logging/logLevel';
import {LoggingSource} from '../logging/loggingSource';
import {UserStatisticsProviderService} from '../services/user-statistics-provider.service';

@Component({
  selector: 'op-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  constructor(private authService: AuthService,
              private connectionsControllerService: ConnectionsControllerService,
              private ws: WebsocketsService,
              public configService: ConfigService) {
    this.messages = [];
  }
  @Input() PortalVersion: string;
  @Input() NewPortalVersion: string;
  @Input() kioskMode = false;

  @Output() toggleKioskMode = new EventEmitter<void>();
  @Output() toggleLogMode = new EventEmitter<void>();

  public messages: Array<any>;
  public logMode: boolean;
  currentUser: User;
  currentStage: string;
  activeUniqueConnections = '1';
  currentYear = new Date().getFullYear();

  protected readonly LoggingService = LoggingService;
  protected readonly LogLevel = LogLevel;
  protected readonly LoggingSource = LoggingSource;


  ngOnInit(): void {
    this.authService.loggedIn.subscribe(loggedIn => {
      this.currentUser = this.authService.currentUser;
      this.initWebSocketConnection();
    });


    this.currentStage = this.configService.getStage();
  }

  ngOnDestroy() {
    this.ws.close();
  }

  private initWebSocketConnection() {

    if (!this.currentUser) {
      return;
    }

    this.ws.getEventListener().subscribe(event => {
      if (event.type === 'message') {
        let data = event.data.content;
        if (event.data.sender) {
          data = event.data.sender + ': ' + data;
        }
        this.messages.push(data);
      }
      if (event.type === 'close') {
        this.messages.push('The socket connection has been closed');
      }
      if (event.type === 'open') {
        this.connectionsControllerService.getConnections().subscribe(
          value => {
            this.activeUniqueConnections = value.toString();
          });

        this.messages.push('The socket connection has been established');
      }
    });
  }

  protected readonly UserStatisticsProviderService = UserStatisticsProviderService;
}
