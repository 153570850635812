import {AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {Part} from '../../services/backend/models/part';
import {PartBlockedState, PartResponse} from '@cstx/volkswagen-mqs-part-service-client';
import {EngineBlockedState, EngineResponse} from '@cstx/volkswagen-mqs-engine-service-client';
import {ActivatedRoute, Router} from '@angular/router';
import {KeycodeHistory} from '../../../modules/engine/list/engine';
import {Engine} from '../../../modules/engine/models/engine';
import {ComponentProfileService} from './component-profile.service';
import {CppItemBase} from '../../models/cppItemBase';
import {PartService} from '../../services/backend/part.service';
import {EngineService} from '../../services/backend/engine.service';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {ComponentType} from './component-type';


@Component({
  selector: 'op-component-profile',
  templateUrl: './component-profile.component.html',
  styleUrls: ['./component-profile.component.scss']
})
export class ComponentProfileComponent implements OnInit, AfterViewChecked {
  @Input() component: CppItemBase;

  public componentProfile = new ComponentProfile();
  public absolutUrl: string;

  private fragment: string;
  private firstRun = true;

  protected readonly ComponentType = ComponentType;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private partService: PartService,
              private engineService: EngineService) {}


  public ngAfterViewChecked() {
    if (this.firstRun && this.fragment) {
      document.querySelector('#' + this.fragment).scrollIntoView({ behavior: 'auto' /*or smooth*/, block: 'center' });
      this.firstRun = false;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.router.onSameUrlNavigation = 'reload';
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });

    // this.refresh();
    await this.load(this.component);

    ComponentProfileService.onComponentReloadRequest.subscribe(async () => {
      /**
       * Something in ComponentProfileService was updated, and now it would be a good idea
       * to reload the whole displayed component to show the newest data.
       *
       * for example: A blocking or release occurred, which means, the component could now have a new
       * blockingState.
       */

      LoggingService.logTrace(LoggingSource.COMPONENT_PROFILE_COMPONENT,
        `ComponentProfileService requested a reload of the component-profile for [${this.componentProfile.name}].`);

        await this.load(this.component, true);
    });
  }

  private async load(component: any, reload: boolean = false) {
    if (component instanceof Engine) {

      if (reload) {
        const response = await this.engineService.getEngineById(component.id);

        if (response) {
          component = new Engine(response);
        }
      }

      this.componentProfile.mapEngineToComponentProfile(component as Engine);
      LoggingService.logTrace(LoggingSource.COMPONENT_PROFILE_COMPONENT,
        `Loaded engine [${this.componentProfile.name}].`);
    }

    if (component instanceof Part) {
      const response = await this.partService.getPartByID(component.id);

      if (response) {
        component = new Part(response);
      }

      this.componentProfile.mapPartToComponentProfile(component as Part);
        LoggingService.logTrace(LoggingSource.COMPONENT_PROFILE_COMPONENT,
          `Loaded part [${this.componentProfile.name}].`);
      }

      this.absolutUrl = this.getCurrentAbsoluteSiteUrl();
    }

  private getCurrentAbsoluteSiteUrl(): string {
    let url: string;
    if (window
      && 'location' in window
      && 'protocol' in window.location
      && 'pathname' in window.location
      && 'host' in window.location) {
      url = window.location.protocol + '//' + window.location.host + window.location.pathname;
    }

    return encodeURI(url);
  }
}

export class ComponentProfile {
  Id: string;
  Identifier: string;
  PreviousIdentifiers: string[];
  public get name(): string {
    if (this.componentType === ComponentType.ENGINE) {
      return this.engine.componentName;
    }

    if (this.componentType === ComponentType.PART) {
      return this.part.dmc;
    }

    return undefined;
  }
  PartNumber: string;
  PartAssemblyGroup: string;
  Manufacturer: string;
  ProductionDate: string;
  Status: string;

  CostCenterWithLineIndex: string;
  CostCenter: string;
  PlantLineIndex: number;
  Line: number;
  Label: any;
  BarcodeValue: string;

  ProductionNumber: string;
  Blocked: boolean;

  CreatedAt: string;
  CreatedBy: string;
  ModifiedAt: string;
  ModifiedBy: string;
  CreationType: string;
  Source: string;

  parentId: string;

  public componentType: ComponentType;
  DevelopmentNumber: string;
  LotSize: number;
  LotNumber: string;

  public engine?: Engine;
  public part?: Part;

  public displacementInLiter?: number[] = null;
  public powerInKilowatts?: number[] = null;
  public aggregate?: string[] = null;
  public typeApprovalLabel?: string;

  public KeyCode: string;
  public KeyCodeHistory = new Array<KeycodeHistory>();


  mapPartToComponentProfile(part: Part): ComponentProfile {
    this.Id = part.id;
    this.Identifier = part.dmc;

    this.CostCenterWithLineIndex = part.costCenter;
    this.CostCenter = this.getCostCenter(part);

    this.PlantLineIndex = part.productionLine;
    this.Line = parseInt(part.costCenter?.substring(4), 10);

    this.Label = part.partType;
    this.PartNumber = part.partNumber;
    this.PartAssemblyGroup = '';
    this.Manufacturer = part.manufacturerCode;
    this.ProductionDate = part.productionDate;

    this.ProductionNumber = part.sequenceNumber?.toString();
    this.BarcodeValue = part.dmc;

    this.Status = part.partStatus;
    this.Blocked = part.partBlockedState === PartBlockedState.Blocked;

    this.ModifiedAt = part.modifiedAt;
    this.ModifiedBy = part.modifiedBy;
    this.CreatedAt = part.createdAt;
    this.CreatedBy = part.createdBy;
    this.Source = part.source;
    this.CreationType = part.creationType;

    this.componentType = ComponentType.PART;
    this.DevelopmentNumber = '';

    if (part.parentId !== undefined && part.parentId !== null && part.parentId !== '') {
      this.parentId = part.parentId;
    }

    this.PreviousIdentifiers = part.previousDmcs;
    this.part = part;

    return this;
  }

  mapEngineToComponentProfile(engine: Engine): ComponentProfile {
    this.Id = engine.id;
    this.Identifier = engine.engineCode + '-' + engine.engineNumber;
    this.CostCenter = engine.costCenter?.substring(0, 4);
    this.CostCenterWithLineIndex = engine.costCenter;
    // tslint:disable-next-line:radix
    this.Line = parseInt(engine.costCenter?.substring(4));

    this.ProductionDate = engine.buildDate;
    this.ProductionNumber = engine.productionNumber;

    this.Status = engine.engineState;

    if (engine.keyCode !== null && engine.engineNumber !== null) {
      this.BarcodeValue = '0' + engine.engineNumber + engine.keyCode + '17';
    }

    this.KeyCode = engine.keyCode;
    this.KeyCodeHistory = engine.keycodeHistory;
    this.typeApprovalLabel = engine.typeApprovalLabel;

    this.Blocked = engine.engineBlockedState === EngineBlockedState.Blocked;

    this.ModifiedAt = engine.modifiedAt;
    this.ModifiedBy = engine.modifiedBy;
    this.CreatedAt = engine.createdAt;
    this.CreatedBy = engine.createdBy;

    this.PartNumber = engine.partNumber ? engine.partNumber : '';
    this.DevelopmentNumber = engine.developmentNumber ? engine.developmentNumber : '';

    this.LotNumber = engine.lotNumber ? engine.lotNumber : undefined;
    this.LotSize = engine.lotSize ? engine.lotSize : undefined;

    this.componentType = ComponentType.ENGINE;
    this.engine = engine;
    return this;
  }

  private getCostCenter(part: Part): any {
    return part.costCenter?.substring(0, 4);
  }
}


