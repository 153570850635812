<div class="input-group input-container pl-2"
     [class.invalidInput]="invalidInput"
     [class.input-group-focused]="inputFocused && !invalidInput"
     [id]="inputId + 'Par'"
     style="flex-wrap: nowrap">
  <div *ngIf="criteria.length > 0" class="criteria-container pr-0 mr-1" style="max-width: 60vw;">
    <div class="criterion" *ngFor="let criterion of criteria; index as i" >
      <div class="criterion-part criterion-left small">
        <span class="m-auto">
          {{ criterion.attribute.key | translate }}
        </span>
      </div>
      <span class="criterion-part small">{{ criterion.operator.key }}</span>
      <span *ngIf="criterion.attribute.inputType === inputTypes.String
            || criterion.attribute.inputType === inputTypes.SelectionAndString"
            class="criterion-part criterion-right small">
        {{ criterion.pattern }}
        <i class="fas fa-times pl-1 ml-1 border-left pointer" (click)="removeCriterion(i)"></i>
      </span>
      <span *ngIf="criterion.attribute.inputType === inputTypes.Selection"
            class="criterion-part criterion-right small">
        {{ criterion.pattern.key | translate }}
        <i class="fas fa-times pl-1 ml-1 border-left pointer" (click)="removeCriterion(i)"></i>
      </span>
      <span *ngIf="criterion.attribute.inputType === inputTypes.DateRange"
            class="criterion-part criterion-right small d-flex flex-row">
        <span style="font-size: 50%; ">
          {{criterion.pattern.from | date: 'dd.MM.yyyy HH:mm'}} <br>
          {{criterion.pattern.to| date: 'dd.MM.yyyy HH:mm'}}
        </span>
        <i class="fas fa-times pl-1 ml-1 border-left my-auto pointer" (click)="removeCriterion(i)"></i>
      </span>
      <span *ngIf="criterion.attribute.inputType === inputTypes.Range"
            class="criterion-part criterion-right small d-flex flex-row text-left">
        <span style="font-size: 50%; ">
          {{ 'global.word.from' | translate }}: {{criterion?.pattern?.from}} <br>
          {{ 'global.word.to' | translate }}: {{criterion?.pattern?.to}}
        </span>
        <i class="fas fa-times pl-1 ml-1 border-left my-auto pointer" (click)="removeCriterion(i)"></i>
      </span>
      <span *ngIf="criterion.attribute.inputType === inputTypes.List"
            class="criterion-part criterion-right small" [tooltip]="listTemp" [placement]="'bottom'" [adaptivePosition]="false">
        <ng-container *ngIf="getPatternAsStringArray(criterion).length === 1">{{ getPatternAsStringArray(criterion)[0] }}</ng-container>
        <ng-container *ngIf="getPatternAsStringArray(criterion).length > 1">{{ getPatternAsStringArray(criterion).length }} {{ 'guidedSearch.inList' | translate }}</ng-container>
        <i class="fas fa-times pl-1 ml-1 border-left pointer" (click)="removeCriterion(i)"></i>
      </span>
      <ng-template #listTemp>
        <ul class="list-unstyled text-left mb-0">
          <li *ngFor="let item of getPatternAsStringArray(criterion)">
            {{ item }}
          </li>
        </ul>
      </ng-template>

    </div>
  </div>
  <div class="criteria-container pr-0">
    <div *ngIf="currentCriterion && currentCriterion.attribute" class="criterion">
      <span class="criterion-part criterion-left small">{{ currentCriterion.attribute.key | translate }}</span>
      <span class="criterion-part small" *ngIf="currentCriterion.operator" >{{ currentCriterion.operator.key }}</span>
      <span class="criterion-part criterion-right small" *ngIf="currentCriterion.pattern && !(
        currentCriterion.attribute.inputType === inputTypes.Range ||
        currentCriterion.attribute.inputType === inputTypes.List ||
        currentCriterion.attribute.inputType === inputTypes.MultiSelection)" >{{ currentCriterion.pattern }}</span>
      <span *ngIf="currentCriterion.pattern && currentCriterion.attribute.inputType === inputTypes.Range"
            class="criterion-part criterion-right small d-flex flex-row text-left">
        <span style="font-size: 50%;">
          {{ 'global.word.from' | translate }}: {{currentCriterion?.pattern?.from}} <br>
          {{ 'global.word.to' | translate }}: {{currentCriterion?.pattern?.to}}
        </span>
      </span>
      <span *ngIf="currentCriterion.pattern && currentCriterion.attribute.inputType === inputTypes.List"
            class="criterion-part criterion-right small">
        {{ getPatternAsStringArray(currentCriterion).length }} {{ 'guidedSearch.inList' | translate }}
      </span>
    </div>
  </div>
  <div class="form-control" style="flex: 0 0 0; width: 0; padding: 0; border: none">

    <div [class.d-none]="!showDropDown()"
         class="input-dropdown"
         (mouseenter)="keepDropdownOpen = true"
         (mouseleave)="keepDropdownOpen = false">
      <ng-container *ngIf="showClassicDrop()">
        <ul class="list-unstyled mb-0 option-container">
          <li style="white-space: nowrap" class="pointer px-2 py-1"
              *ngFor="let option of displayedOptions"
              (click)="selectOption(option)"
              [class.bg-secondary]="option===selectedOption"
              [class.text-white]="option===selectedOption">
              <i *ngIf="option.hasDecorationIcon"
                 [ngClass]="option.decorationClasses"></i>
              {{ option.key | translate }}
          </li>
          <li *ngIf="displayedOptions.length === 0" style="white-space: nowrap" class="pointer px-2 py-1 text-muted font-weight-bold">
            {{ 'guidedSearch.noMatch' | translate}}
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="currentCriterion?.attribute?.inputType === inputTypes.List">
        <div class="p-2 container">
          <div class="row no-gutters" *ngIf="getPatternAsStringArray(currentCriterion).length === 0">
            <div class="col text-muted text-center">
              <span>{{ 'global.word.no-entries' | translate }}</span>
            </div>
          </div>
          <div class="row no-gutters" *ngFor="let item of getPatternAsStringArray(currentCriterion); index as i">
            <div class="col position-relative" style="padding-right: 20px">
              <span class="float-left">{{ item }}</span>
              <span class="position-absolute" style="width: 15px; right:0"><i class="fas fa-times-circle pointer" (click)="removeEntryFromCurrentCriterionPattern(i)"></i></span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <input #criteriaInput
         [id]="inputId"
         type="text"
         class="form-control border-0 pl-0"
         aria-label="Search"
         style="min-width: 200px; height: calc(1.5em + .75rem); margin-right: 1px"
         (ngModelChange)="filterDisplayedOptions($event)"
         [(ngModel)]="searchString"
         (keydown.enter)="onEnter()"
         (keydown.escape)="reset()"
         (keydown.arrowDown)="onDown()"
         (keydown.arrowUp)="onUp()"
         (keydown.tab)="onTab($event)"
         [disabled]="searching || disabled"
         (focusin)="onFocus()"
         (focusout)="onBlur()"
         (keydown.backspace)="onBackspace()">
  <i *ngIf="searchString || step !== steps.Attribute || criteria.length > 0" class="cpp-icon-clear-input pointer" (click)="emitSearchAbort()"></i>
  <div class="input-group-append">
    <span class="input-group-text border-bottom-0 border-right-0 border-top-0">
      <i class="fas fa-search pointer" (click)="emitSearch()"></i>
    </span>
  </div>
</div>

