import {FilterBase} from '../../../shared/models/filterBase';
import {Slice} from '../../../shared/models/slice';
import {EngineState} from '@cstx/volkswagen-mqs-report-service-client/model/engineState';

export class EngineFilter extends FilterBase {
  public engineState?: EngineState;
  public engineBlockedState?: 'NONE' | 'BLOCKED';
  public engineCode = '';
  public engineNumber = '';
  public keyCode = '';
  public costCenter = '';
  public buildDateFrom?;
  public buildDateTo?;
  public slice: Slice;

  constructor() {
    super();

    this.pagingSize = 50;
    this.totalPages = 1;
    this.currentPageIndex = 0;
    this.sort = ['modifiedAt,desc'];
  }

  public getAllParams() {
    return {...this.getPageableParams(), ...this.getFilterParams()};
  }
  public getFilterParams() {
    if (this.IsEmpty()) {
      return {};
    }

    return {
      engineBlockedState: this.engineBlockedState,
      buildDateFrom: this.buildDateFrom,
      buildDateTo: this.buildDateTo,
      costCenter: this.costCenter,
      engineCode: this.engineCode,
      engineNumber: this.engineNumber,
      engineState: this.engineState,
      keyCode: this.keyCode
    };
  }

  public getPageableParams() {
    return {
      page: this.currentPageIndex,
      size: this.pagingSize,
      sort: this.sort,
    };
  }

  public IsEmpty(): boolean {
    if (this.engineState) {
      return false;
    }
    if (this.engineCode !== '') {
      return false;
    }
    if (this.engineNumber !== '') {
      return false;
    }
    if (this.keyCode !== '') {
      return false;
    }
    if (this.costCenter !== '') {
      return false;
    }
    if (this.buildDateFrom || this.buildDateTo) {
      return false;
    }

    return true;
  }
}
