import {Injectable} from '@angular/core';
import {ExportQMSPartListV3RequestParams, ReportGeneratorService} from '@cstx/volkswagen-mqs-report-service-client';
import {LoggingService} from '../../../core/logging/logging.service';
import {EngineFilter} from '../../../modules/engine/list/enginefilter';
import {FilterBase} from '../../models/filterBase';
import {PartFilter} from '../../services/backend/models/filter/partfilter';
import {firstValueFrom} from 'rxjs';
import {ToolCorrectionFilter} from 'src/app/modules/actions/toolcorrection/components/list/models/toolcorrectionfilter';
import {PartFilterCriteriaV3} from '@cstx/volkswagen-mqs-report-service-client/model/partFilterCriteriaV3';
import {LoggingSource} from '../../../core/logging/loggingSource';

@Injectable({
  providedIn: 'root'
})
export class ReportService {


  constructor(private reportController: ReportGeneratorService) {
  }

  public requestReport(filterBase: FilterBase): Promise<any> {
    if (filterBase instanceof EngineFilter) {
     return this.generateEngineReport(filterBase);
    } else if (filterBase instanceof PartFilter) {
     return this.generatePartReport(filterBase);
    }
    else if (filterBase instanceof ToolCorrectionFilter) {
      return this.generateToolCorrectionReport(filterBase);
     }
    return Promise.reject({error: 'not applicable filter' });
  }

  public requestFilterReport(filterId: string, title: string, description: string): Promise<void> {
    return this.generateQMSReport(filterId, title, description);
  }

  private generatePartReport(filterBase: PartFilter): Promise<void> {

  const partFilterCriteriaV3: PartFilterCriteriaV3 ={
      parentId: filterBase.parentId,
      manufacturerCodeList: filterBase.manufacturerCode,
      partNumberList: filterBase.partNumber,
      partType: filterBase.partType,
      dmc: filterBase.dmc,
      costCenterList: filterBase.costCenter,
      productionLine: null,
      partStatus:  filterBase.getMappedPartState(),
      partBlockedState: filterBase.partBlockedState,
      sequenceNumberFrom: filterBase.sequenceNumberFrom,
      sequenceNumberTo: filterBase.sequenceNumberTo,
      partInstallationStatus: null,
      productionDateFrom: filterBase.productionDateFrom,
      productionDateTo: filterBase.productionDateTo,
      modifiedAtFrom: null,
      modifiedAtTo: null,
    }
    const exportQMSPartListV3RequestParams :ExportQMSPartListV3RequestParams= {
      partReportCreateRequestV3:{
        title: '',
        timezone: '',
        outputType: 'Excel',
        partFilterCriteriaV3
      }
    }

    return new Promise((resolve, reject) => {
      firstValueFrom(this.reportController.exportQMSPartListV3(
        exportQMSPartListV3RequestParams,'response'))
      .then(() => {
        LoggingService.logDebug(LoggingSource.REPORT_SERVICE, 'Successful request part list report file');
        resolve();
      })
      .catch((reason) => {
        LoggingService.logError(LoggingSource.REPORT_SERVICE, 'Error occurred when downloading report file', reason);
        reject(reason);
      });
    });
  }

  private generateEngineReport(filterBase: EngineFilter): Promise<void> {
    return new Promise(((resolve, reject) => {
      firstValueFrom(this.reportController.exportQMSEngineListV3({
        engineReportCreateRequestV3: {
          title: '',
          timezone: '',
          outputType: 'Excel',
          engineFilterCriteriaV3: {
            engineState: filterBase.engineState ,
            engineBlockedState: filterBase.engineBlockedState,
            engineCodeFrom:  null,
            engineCodeTo:  null ,
            engineNumberFrom:  filterBase.engineNumberFrom,
            engineNumberTo:  filterBase.engineNumberTo,
            partNumberList:  filterBase.partNumber,
            developmentNumber:  filterBase.developmentNumber,
            lotNumber:  null,
            orderNumber:  filterBase.orderNumber,
            engineCodeList:  filterBase.engineCode,
            engineNumberList:  filterBase.engineNumber,
            keyCodeList:  filterBase.keyCode,
            costCenter:  filterBase.costCenter,
            buildDateFrom:  filterBase.buildDateFrom,
            buildDateTo:  filterBase.buildDateTo,
          }
        }
      }, 'response'))
        .then(() => {
          LoggingService.logDebug(LoggingSource.REPORT_SERVICE, 'Successful request engine list report file');
          resolve();
        })
        .catch((reason) => {
          LoggingService.logError(LoggingSource.REPORT_SERVICE, 'Error occurred when downloading report file', reason);
          reject(reason);
        });
    }));
  }

  private generateToolCorrectionReport(filterBase: ToolCorrectionFilter): Promise<void> {
    return new Promise(((resolve, reject) => {
      firstValueFrom(this.reportController.exportQMSToolCorrectionList({
        toolCorrectionReportCreateRequest: {
          title: '',
          timezone: '',
          outputType: 'Excel',
          toolCorrectionFilterCriteria: filterBase.getFilterParams()
        }
      }, 'response'))
        .then(() => {
          LoggingService.logDebug(LoggingSource.REPORT_SERVICE, 'Successful request toolcorrection list report file');
          resolve();
        })
        .catch((reason) => {
          LoggingService.logError(LoggingSource.REPORT_SERVICE, 'Error occurred when downloading report file', reason);
          reject(reason);
        });
    }));
  }

  private generateQMSReport(filterId: string, title: string, description: string): Promise<void> {
    return new Promise(((resolve, reject) => {
      firstValueFrom(this.reportController.exportQMSFilterResults({
        exportQMSFilterResultsCreateRequest: {
          filterId,
          title,
          outputType: 'Excel',
        }
      })).then(response => {
        LoggingService.logDebug(LoggingSource.REPORT_SERVICE, 'GenerateQMSReport successfully done.');
        resolve();
      })
        .catch((reason) => {
          LoggingService.logError(LoggingSource.REPORT_SERVICE, 'GenerateQMSReport failed.', reason);
          reject(reason)
        });
    }));
  }
}
