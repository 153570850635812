import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'op-count-badge',
  templateUrl: './count-badge.component.html',
  styleUrls: ['./count-badge.component.scss']
})
export class CountBadgeComponent implements OnInit, OnChanges {
  @Input() count: any = 0;
  @Input() title: string;
  @Input() color: string;
  @Input() width: string;
  @Input() iconClass: string;

  countString = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.count === 0) {
      this.countString = '0';
    } else {
      this.countString = this.formatNumber(this.count);
    }
  }
  formatNumber(n: number): string  {
    if (!n) { return; }

    if (n < 1e3) { return n.toString(); }
    if (n >= 1e3 && n < 1e6) { return +(n / 1e3).toFixed(1) + 'k'; }
    if (n >= 1e6 && n < 1e9) { return +(n / 1e6).toFixed(1) + 'm'; }
    if (n >= 1e9 && n < 1e12) { return +(n / 1e9).toFixed(1) + 'b'; }
    if (n >= 1e12) { return +(n / 1e12).toFixed(1) + 't'; }
  }
}
