import {BigScreenSettings, DivergentBigScreenTargetValueType} from './BigScreenSettings';
import {
  BigScreenConfigurationEntity,
  BigScreenMetricType as BigScreenMetricTypeBackend,
  BigScreenTrendType as BigScreenTrendTypeBackend,
  CostCenterConfigurationResponse,
  DivergentBigScreenTargetValueType as BackendDivergentBigScreenTargetValueType
} from '@cstx/volkswagen-mqs-user-configuration-service-client';
import {BigScreenMetricType} from '../../../tools/bigscreen2/BigScreenMetricType';
import {BigScreenTrendType} from '../../../tools/bigscreen2/BigScreenTrendType';

export class CostCenterConfiguration {
  public id: string;
  public costCenter: string;
  public createToolCorrectionProcessOnYellowReports = false;
  public bmaLineAlias?: string;
  public alertDurationInMinutes = 3;
  public supportRequestDurationInMinutes = 3;

  public bigScreenSettings: BigScreenSettings = new BigScreenSettings();


  constructor(costCenter?: string) {
    if (costCenter) {
      this.costCenter = costCenter;
    }
  }

  public mapFromResponse(response: CostCenterConfigurationResponse) {
      this.id = response ? response.id : undefined;
      this.costCenter = response ? response.costCenter : null;
      this.alertDurationInMinutes = response ? response.alertDurationInMinutes : this.alertDurationInMinutes;
      this.supportRequestDurationInMinutes = response ? response.supportRequestDurationInMinutes : this.supportRequestDurationInMinutes;

      this.bigScreenSettings = new BigScreenSettings();
      this.bigScreenSettings.id = response ? response.bigScreenConfiguration.id : undefined;
      this.bigScreenSettings.divergentBigScreenTargetValueActive = response ? response.bigScreenConfiguration.divergentBigScreenTargetValueActive : false;
      this.bigScreenSettings.divergentBigScreenTargetValue = response ? response.bigScreenConfiguration.divergentBigScreenTargetValue : 0;
      this.bigScreenSettings.divergentBigScreenTargetValueType
        = response ? (response.bigScreenConfiguration.divergentBigScreenTargetValueType
        === BackendDivergentBigScreenTargetValueType.Absolute
        ? DivergentBigScreenTargetValueType.ABSOLUTE
        : DivergentBigScreenTargetValueType.PERCENT)
        : DivergentBigScreenTargetValueType.PERCENT

      this.bigScreenSettings.forceReload = response ? ( response.bigScreenConfiguration.reloadBigScreen ? response.bigScreenConfiguration.reloadBigScreen : false ) : false;
      this.bigScreenSettings.forceReloadTriggerTimeStamp = response ? response.bigScreenConfiguration.reloadBigScreenTimestamp : 0;

      this.bigScreenSettings.bigScreenMetricType = response ? this.getBigScreenMetricType(response.bigScreenConfiguration) : null;
      this.bigScreenSettings.bigScreenTrendType = response ? this.getBigScreenTrendType(response.bigScreenConfiguration) : null;
      this.bmaLineAlias = response ? response.bmaLineAlias : null;
  }

  private getBigScreenMetricType(bigScreenConfiguration: BigScreenConfigurationEntity): BigScreenMetricType {
    if (bigScreenConfiguration.bigScreenMetricType === BigScreenMetricTypeBackend.CapacityActualTrend) {
      return BigScreenMetricType.CAPACITY_ACTUAL_TREND;
    }

    if (bigScreenConfiguration.bigScreenMetricType === BigScreenMetricTypeBackend.CapacityActualCurrentCapacity) {
      return BigScreenMetricType.CAPACITY_ACTUAL_CURRENT_CAPACITY;
    }

    if (bigScreenConfiguration.bigScreenMetricType === BigScreenMetricTypeBackend.TargetActualTrend) {
      return BigScreenMetricType.TARGET_ACTUAL_TREND;
    }

    return null;
  }

  private getBigScreenTrendType(bigScreenConfiguration: BigScreenConfigurationEntity): BigScreenTrendType {
    if (bigScreenConfiguration.bigScreenTrendType === BigScreenTrendTypeBackend.Linear) {
      return BigScreenTrendType.Linear;
    }

    if (bigScreenConfiguration.bigScreenTrendType === BigScreenTrendTypeBackend.Optimistic) {
      return BigScreenTrendType.Optimistic;
    }

    if (bigScreenConfiguration.bigScreenTrendType === BigScreenTrendTypeBackend.SzOnlineV1) {
      return BigScreenTrendType.SZOnlineV1;
    }

    return null;
  }
}
