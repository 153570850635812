<div class="fixed-bottom bg-dark text-light text-center padding-bottom"
     [class.cpp-stage-indicator-bottom-develop]="currentStage === 'develop' || currentStage === 'localhost'"
     [class.cpp-stage-indicator-bottom-integration]="currentStage === 'integration'"
     [class.cpp-stage-indicator-bottom-production]="currentStage === undefined">
    <span class="navbar-dark small">©{{ currentYear }}
      <a href="https://www.volkswagen.de/" class="text-light">Volkswagen AG</a>
      - v{{ PortalVersion }}
        <ng-container *ngIf="NewPortalVersion">
            <div class="d-inline" [tooltip]="newVersionTooltip">
                <i class="fas fa-info-circle" style="color: darkgoldenrod"></i> {{ NewPortalVersion }}
            </div>

            <ng-template #newVersionTooltip>
                Eine neue Portalversion steht zur Verfügung. Bitte Seite neuladen.
            </ng-template>
        </ng-container>
    </span>
    <ng-container *ngIf="!kioskMode">
      <ng-container *ngIf="currentUser">
        <div class="d-none d-sm-none d-md-inline">
          <span class="navbar-dark small">

            (
            <label>{{ 'footer.infos.activeUser' | translate }}: {{ UserStatisticsProviderService.activeUserCount }}</label>

            <label *ngIf="UserStatisticsProviderService.activeAppsCount > 0" class="pl-1">
              | {{ 'footer.infos.activeApps' | translate }}: {{ UserStatisticsProviderService.activeAppsCount }}
            </label>
            )
          </span>


          <ng-container *opRequiredRole="'role_admin'">
            <ng-container *ngIf="UserStatisticsProviderService.activeUserIds">
              <div class="d-inline" style="vertical-align: middle">
            <i class="fas fa-list pl-1 pt-1" [tooltip]="userIdsTemplate"></i>
              </div>
            <ng-template #userIdsTemplate>
              <ng-container *ngFor="let id of UserStatisticsProviderService.activeUserIds">
                {{ id }} <br>
              </ng-container>
            </ng-template>
            </ng-container>
          </ng-container>

        </div>
      </ng-container>
    <div class="d-none d-sm-none d-md-inline">
      <span class="small">
        <a href="https://www.volkswagen.de/de/mehr/impressum.html"
           class="text-light" target="_blank" rel="noopener noreferrer"> - {{ 'footer.impressum' | translate }}</a> -
        <a href="https://datenschutz.volkswagen.de/" _target="_blank" class="text-light">{{'footer.privacyStatement' | translate}}</a>
      </span>
    </div>
  </ng-container>
  <i *ngIf="currentUser && !(currentUser?.applicationUser)" class="float-right fas m-2 pr-2 pointer" [class.fa-compress]="kioskMode" [class.fa-expand]="!kioskMode" (click)="toggleKioskMode.emit()"></i>
  <i *ngIf="currentUser" class="float-right fas fa-lightbulb m-2 pr-2 pointer"
     [class.text-warning]="(LoggingService.getNewMessageIndicator() | async) === LogLevel.WARNING"
     [class.text-danger]="(LoggingService.getNewMessageIndicator() | async) === LogLevel.ERROR"
     (click)="toggleLogMode.emit()"></i>
</div>

