import {Injectable} from '@angular/core';
import {
  ComponentCreateRequest, ComponentType as TrackingServiceComponentType,
  Source,
  StackControllerService,
  StackControllerV2Service,
  StackResponse,
  StatusControllerService,
  TrackingEventControllerService,
  TrackingEventCreateRequest,
  TrackingEventCreateResponse,
} from '@cstx/volkswagen-mqs-tracking-service-client';
import {firstValueFrom} from 'rxjs';
import {ErrorHandler} from '../error-handler/error-handler';
import {StackFilter} from '../../models/tracking/stackFilter';
import {StackV2} from './models/stackv2';
import {StacksSlice} from './models/StacksSlice';
import {BackendStatus} from './models/BackendStatus';
import {LoggingService} from '../../../core/logging/logging.service';
import {LoggingSource} from '../../../core/logging/loggingSource';
import {Stack} from '../../../modules/tracking/loadings/models/stack';
import {Component} from '../../../modules/tracking/loadings/models/component';
import {AuthService} from '../../../core/services/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {EngineService} from './engine.service';
import {PartService} from './part.service';
import {Engine} from './models/engine';
import {BackendService} from './models/backend-service';
import {ConfigService} from '../../../core/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingService extends BackendService {
  private createdBy = 'SYSTEM';

  constructor(private stackControllerService: StackControllerService,
              private stackControllerServiceV2: StackControllerV2Service,
              private statusControllerService: StatusControllerService,
              private trackingEventControllerService: TrackingEventControllerService,
              private engineService: EngineService,
              private partService: PartService,
              private authService: AuthService) {

    super('tracking-service', ConfigService.configuration.tgsApiUrl);

    this.authService.loggedIn.subscribe(loggedIn => {
      if (loggedIn) {
        this.createdBy = this.authService.currentUser.username;
      }
    });
  }

  /**
   * This method returns a stack by given stack identifier (mbk or gtl).
   * @param identifier id of the stack
   * @return Promise<StackResponse> A stackResponse object or null is returned.
   */

  public async getStackByStackIdentifier(identifier: string, loadComponentsFull: boolean = false): Promise<StackV2> {
    let result: StackV2 = null;
    try {
      const response = await firstValueFrom(this.stackControllerService.getStack(
          { stackIdentifier: identifier}))

      if (response) {
        result = new StackV2(response);


        if (loadComponentsFull) {
          const engineIds: Array<string> = response.components
            .filter(component => component.componentType === 'ENGINE')
            .map(component => component.componentId);

          result.engines = await this.engineService.getEnginesByIDList(engineIds);
          result.enginesV2 = result.engines.map(engineResponse => new Engine(engineResponse));

          const partIds: Array<string> = response.components
            .filter(component => component.componentType === 'PART')
            .map(component => component.componentId);

          result.parts = await this.partService.getPartByIDList(partIds);
        }
      }
    }
    catch (error) {
      if (error instanceof HttpErrorResponse &&  (error as HttpErrorResponse).status === 404) {
        LoggingService.logWarning(LoggingSource.STACK_SERVICE,
            `No data for identifier ${identifier} found. Empty StackIdentifier scanned.`, error);
      } else {
        LoggingService.logError(LoggingSource.TRACKING_SERVICE,
            `Backend error occurred when trying to receive a stack by identifier from tracking-service.`, error);
      }
    }

    return result;
  }

  /**
   * This method returns a stacks list for the given ids.
   * @param list The ids we want to get stack information for.
   * @param pagingSize Size of the pages to get. Default 250.
   * @return Promise<Array<StackResponse>> A stacks object array or an empty list.
   */
  public async getStacksByComponentIDList(list: string[], pagingSize: number = 250): Promise<Array<StackResponse>> {
    let response = new Array<StackResponse>();
    let allRequested = false;


    try {
      while (!allRequested) {
        let toGet: string[];

        if (list.length > 0) {
          toGet = list.splice(0, pagingSize);

          const result = await firstValueFrom(this.stackControllerService.getAllStacksByComponentIDList({
            stackListRequest: {
              ids: toGet
            }
          }));

          if (response.length === 0) {
            response = result;
          } else {
            response.push(...result);
          }

        } else {
          allRequested = true;
        }
      }
    } catch (error) {
      ErrorHandler.printError(error);
    }

    return response;
  }

  /**
   * This method returns a paged stacklist.
   * @param filter The filter we want to use to find the correct stacks for
   * @return Promise<StacksSlice> A stacks slice object array or an empty list.
   */

  public async getStacks(filter: StackFilter): Promise<StacksSlice> {
    const resultContent = new Array<StackV2>();

    const result = new StacksSlice();
    result.content = resultContent;

    try {
      const response = await firstValueFrom(this.stackControllerServiceV2.getStacksV2(filter.getAllParams()));

      response.content.forEach(s => {
        const stackV2 = new StackV2(s);
        result.content.push(stackV2);
      })

      result.first = response.first;
      result.empty = response.empty;
      result.last = response.last;
      result.size = response.size;
      result.pageNumber = response.page;

      return result;
    } catch (error) {
      ErrorHandler.printError(error);
    }

    result.empty = true;
    result.pageNumber = 0;
    result.last = true;
    result.first = true;
    result.pageNumber = 0;
    return result;
  };

  public async getStack(id: string): Promise<StackV2> {
    let result: StackV2;
    try {
      const response = await firstValueFrom(this.stackControllerService.getStack({stackIdentifier: id}))
      result = new StackV2(response);

      const engineIds: Array<string> = response.components
        .filter(component => component.componentType === 'ENGINE')
        .map(component => component.componentId);
      const partIds: Array<string> = response.components
        .filter(component => component.componentType === 'PART')
        .map(component => component.componentId);

      result.engines = await this.engineService.getEnginesByIDList(engineIds);
      result.enginesV2 = result.engines.map(engineResponse => new Engine(engineResponse));
      result.parts = await this.partService.getPartByIDList(partIds);


    } catch (error) {
      ErrorHandler.printError(error);
    }
    return result;
  }

  /**
   * This method returns a stacks count.
   * @param filter The filter we want to use to find the correct stacks count.
   * @return Promise<number> The current count of stacks as number or -1 is returned.
   */
  public async getStacksCount(filter: StackFilter): Promise<number> {
    try {
      return await firstValueFrom(this.stackControllerServiceV2.getStacksCount(filter.getAllParams()));
    } catch (error) {
      ErrorHandler.printError(error);
    }

    return -1;
  }


  /**
   * This method checks the status of the tracking service backend.
   * @return Promise<BackendStatus> The current status of the backend.
   */
  public async getStatus(): Promise<BackendStatus> {
    let status = BackendStatus.FAILED;
    try {
      const response = await firstValueFrom(this.statusControllerService.getStatus());
      if (response.status.toString() === 'OK/Running') {
        status = BackendStatus.UP;
      }

    } catch (error) {
      LoggingService.logError(LoggingSource.TRACKING_SERVICE, 'Backend error occurred when trying to receive backend status for tracking-service.', error);
    }

    return status;
  }


  /**
   * This method created a new tracking event of type STACKING.
   * @param stack
   * @return boolean True if the request was successful and false if any kind of error occurred.
   *
   */

  public async postStack(stack: Stack): Promise<boolean> {
    const req: TrackingEventCreateRequest = {
      origin: 'StackingApp',
      source: Source.Szonlinev2,
      line: stack.componentCostCenter,
      eventTime: stack.stackedAtString,
      eventType: 'STACKING',
      referenceProperties: {keyCode: stack.componentKeyCode},
      stack: {
        costCenter: stack.componentCostCenter,
        stackIdentifier: stack.stackIdentifier,
        components: this.mapComponentsToComponentCreateRequests(stack.components)
      }};

    const response = await this.createTrackingEvent(req);
    return response !== null;
  }


  /**
   * This method creates a new tracking event for the given create request.
   * @param createRequest
   */

  public async createTrackingEvent(createRequest: TrackingEventCreateRequest): Promise<TrackingEventCreateResponse[]> {
    let result: TrackingEventCreateResponse[] = null;

    try {
      result = await firstValueFrom(this.trackingEventControllerService
        .createTrackingEvent( { trackingEventCreateRequest: createRequest }));

    } catch(error) {
      LoggingService.logError(LoggingSource.TRACKING_SERVICE, '' +
        'Backend error occurred when trying to create new tracking event.', error);
    }

    return result;
  }

  private mapComponentsToComponentCreateRequests(components: Component[]) {
    const componentCreateRequests = new Array<ComponentCreateRequest>();
    components.forEach(c => {
      const req = {
        componentName: c.name,
        componentType: c.type === 'PART' ? TrackingServiceComponentType.Part : TrackingServiceComponentType.Engine,
        componentId: c.id
      };

      componentCreateRequests.push(req);
    });

    return componentCreateRequests;
  }
}


